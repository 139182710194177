import React, { useState, useEffect } from 'react';
import { auth, db, storage } from '../Firebase/firebaseConfig';
import { updateEmail, updatePassword, deleteUser, updateProfile } from "firebase/auth";
import { doc, getDocs, query, collection, where, updateDoc, getDoc, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import '../Styles/SettingsForm.css';
import logo from '../Resources/icon.png';
import eyeIcon from '../Resources/eye-filled.svg';
import eyeOffIcon from '../Resources/eye-off-filled.svg';
import backIcon from '../Resources/back-arrow.png';
import { UilUser } from '@iconscout/react-unicons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '../Components/ConfirmationModal';
import { isUsernameUnique } from '../Firebase/readUserDetails';


function SettingsForm() {
    const [user, setUser] = useState(auth.currentUser);
    const [profilePic, setProfilePic] = useState('');
    const [profilePicUrl, setProfilePicUrl] = useState(user.photoURL);
    const [username, setUsername] = useState('');
    const [originalUsername, setOriginalUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState(user.email);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isPrivate, setIsPrivate] = useState(false);
    const [isSearchable, setIsSearchable] = useState(true);
    const [runLocalOnly, setRunLocalOnly] = useState(true);
    const [runLargeModel, setRunLargeModel] = useState(true);
    const [useCuda, setUseCuda] = useState(true);
    const [errorUsername, setUsernameError] = useState('');
    const [errorPassword, setPasswordError] = useState('');
    const [errorFirstName, setFirstNameError] = useState('');
    const [errorLastName, setLastNameError] = useState('');
    const [errorEmail, setEmailError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
          const userDocRef = doc(db, 'users', auth.currentUser.uid);
          const userSnap = await getDoc(userDocRef);
          if (userSnap.exists()) {
              const userData = userSnap.data();
              setFirstName(userData.firstName || '');
              setLastName(userData.lastName || '');
              setUsername(userData.username || '');
              setOriginalUsername(userData.username || '');
              setEmail(auth.currentUser.email || '');
              setIsPrivate(userData.isPrivate);
              setIsSearchable(userData.isSearchable);
              setRunLocalOnly(userData.runLocalOnly ?? true);
              setRunLargeModel(userData.runLargeModel ?? true);
              setUseCuda(userData.useCuda ?? true);
          }
        }
        fetchUserData();

        // Fetch and set user data from Firestore
        if (user.photoURL) {
            setProfilePicUrl(user.photoURL);
        }

    }, [user]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = true;

        setEmailError("");
        setFirstNameError("");
        setPasswordError("");
        setLastNameError("");
        setUsernameError("");

        // Validate email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError('Invalid email format');
            isValid = false;
        }

        // Validate password
        if (password && (password.length < 8 || password.length > 16)) {
            setPasswordError('Password must be 8-16 characters long');
            isValid = false;
        }

        // Validate First Name
        if (firstName && firstName.length > 32) {
            setFirstNameError('First Name must be less than 32 characters long');
            isValid = false;
        }

        // Validate Last Name
        if (lastName && lastName.length > 32) {
            setLastNameError('Last Name must be less than 32 characters long');
            isValid = false;
        }

        // Validate username length
        if (username && (username.length < 8 || username.length > 32)) {
          setUsernameError('Username must be 8-32 characters');
          isValid = false;
        }

        // Regex to check for allowed characters (alphanumeric and underscores)
        const usernameRegex = /^[a-zA-Z0-9_.]+$/;

        // Validate username characters
        if (username && !usernameRegex.test(username) && errorUsername === '') {
            setUsernameError('Username can only contain alphanumeric characters, underscores and periods.');
            isValid = false;
        }

        // Validate username uniqueness
        if (username && username.toLowerCase() !== originalUsername.toLowerCase() && errorUsername === '') {
            const isUnique = await isUsernameUnique(username);
            if (!isUnique) {
                setUsernameError("Username already taken.");
                isValid = false;
            }
        }

        if (!isValid) {
            toast.error("Error updating profile. Please try again.");
            return; // Stop the function if validation fails
        }

        try {
            // Update email if it has changed and is valid
            if (email !== user.email) {
                await updateEmail(user, email);
            }

            // Update password if entered
            if (password) {
                await updatePassword(user, password);
            }

            // Update Firestore document
            const userDocRef = doc(db, "users", user.uid);
            await updateDoc(userDocRef, {
                username: username.toLowerCase(), // Store username in lowercase for consistency
                firstName,
                lastName,
                isPrivate,
                isSearchable,
                runLocalOnly,
                runLargeModel,
                useCuda
            });

            toast.success("Profile updated successfully!");
        } catch (error) {
            toast.error("Error updating profile: " + error.message);
        }
    };

    const handleProfilePicChange = async (event) => {
        console.log(event);
        const file = event.target.files[0];  // Get the uploaded file
        if (!file) return;  // Check if a file was actually selected
        // Check if user object is correctly retrieved and has a uid
        if (!user || !user.uid) {
            console.error('User is undefined or lacks a UID');
            return; // Exit the function if user or UID is not valid
        }


        try {
            // Create a reference for the new profile picture in Firebase Storage
            const imageRef = ref(storage, `profilePictures/${user.uid}`);

            // Upload the image to Firebase Storage
            await uploadBytes(imageRef, file);

            // Get the URL of the uploaded image
            const photoURL = await getDownloadURL(imageRef);

            // Update the user's profile with the new photo URL
            await updateProfile(user, { photoURL });

            // Update local user state to force re-render and show new photo
            setUser({ ...user, photoURL });  // Update the user's photoURL in your local state if needed
            toast.success("Successfully uploaded profile picture!");
        } catch (error) {
            toast.error("Error updating profile picture: " + error.message);
        }
    };

    const handleDeleteAccount = async () => {
        try {
            const userDocRef = doc(db, "users", user.uid);
            // Update the user document with a deletion timestamp
            await updateDoc(userDocRef, {
                deletedAt: serverTimestamp(), // Use Firebase server timestamp
            });

            await deleteUser(auth.currentUser);
            toast.success("Account deleted successfully.");
            navigate('/login');
        } catch (error) {
            toast.error("Error deleting account: " + error.message);
        }
    };

    const handleBackClick = () => {
        navigate(-1); // Navigate back in history
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="settings-background">

            <div className="settings-container">
                <header className="settings-header">
                  <button onClick={handleBackClick} className="back-button">
                    <img src={backIcon} alt="Back" className="back-icon" />
                  </button>
                  <h1 className="settings-title">Edit Profile</h1>
                  <div className="back-icon"></div>
                </header>

                <div className="profile-pic-container">
                    {profilePicUrl ? (
                        <img src={profilePicUrl} alt="Profile" className="profile-pic" />
                    ) : (
                        <UilUser className="profile-pic" />
                    )}
                    <input type="file" onChange={handleProfilePicChange} className="file-input" />
                </div>

                <form onSubmit={handleSubmit} className="settings-form">

                  <div>
                    <div className="settings-section">
                        <label className="settings-label">Username</label>
                        <input type="text" className="settings-input" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </div>

                    <div className="settings-section">
                      <label className="settings-label"></label>
                      {errorUsername && <p className="settings-error">{errorUsername}</p>}
                    </div>
                  </div>

                    <div>
                      <div className="settings-section">
                          <label className="settings-label">First Name</label>
                          <input type="text" className="settings-input" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                      </div>

                      <div className="settings-section">
                        <label className="settings-label"></label>
                        {errorFirstName && <p className="settings-error">{errorFirstName}</p>}
                      </div>
                    </div>


                    <div>
                      <div className="settings-section">
                          <label className="settings-label">Last Name</label>
                          <input type="text" className="settings-input" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                      </div>

                      <div className="settings-section">
                        <label className="settings-label"></label>
                        {errorLastName && <p className="settings-error">{errorLastName}</p>}
                      </div>

                    </div>

                    <div>

                      <div className="settings-section">
                          <label className="settings-label">Email</label>
                          <input className="settings-input" value={email} onChange={(e) => setEmail(e.target.value)} />
                      </div>

                      <div className="settings-section">
                        <label className="settings-label"></label>
                        {errorEmail && <p className="settings-error">{errorEmail}</p>}
                      </div>

                    </div>

                    <div>
                      <div className="settings-section">
                        <label className="settings-label">Password</label>
                        <div className="password-container">
                            <input
                                type={showPassword ? "text" : "password"}
                                className="settings-input"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="leave blank to keep the same password"
                            />
                            <img
                                src={showPassword ? eyeIcon: eyeOffIcon}
                                alt="Toggle Password Visibility"
                                className="toggle-password-visibility"
                                onClick={togglePasswordVisibility}
                            />
                        </div>
                      </div>
                      <div className="settings-section">
                        <label className="settings-label"></label>
                        {errorPassword && <p className="settings-error">{errorPassword}</p>}
                      </div>
                    </div>


                    <div className="settings-section">
                        <label className="settings-label">Private Account</label>
                        <label className="switch">
                            <input type="checkbox" checked={isPrivate} onChange={() => setIsPrivate(!isPrivate)} />
                            <span className="slider round"></span>
                        </label>
                    </div>

                    <div className="settings-section">
                        <label className="settings-label">Searchable Account</label>
                        <label className="switch">
                            <input type="checkbox" checked={isSearchable} onChange={() => setIsSearchable(!isSearchable)} />
                            <span className="slider round"></span>
                        </label>
                    </div>

                    <div className="settings-section">
                        <label className="settings-label">Local AI Only (most secure, runs on device)</label>
                        <label className="switch">
                            <input type="checkbox" checked={runLocalOnly} onChange={() => setRunLocalOnly(!runLocalOnly)} />
                            <span className="slider round"></span>
                        </label>
                    </div>

                    <div className="settings-section">
                        <label className="settings-label">Use GPU if available (faster processing)</label>
                        <label className="switch">
                            <input type="checkbox" checked={useCuda} onChange={() => setUseCuda(!useCuda)} />
                            <span className="slider round"></span>
                        </label>
                    </div>

                    <div className="settings-section">
                        <label className="settings-label">Large AI (most accurate, but slower)</label>
                        <label className="switch">
                            <input type="checkbox" checked={runLargeModel} onChange={() => setRunLargeModel(!runLargeModel)} />
                            <span className="slider round"></span>
                        </label>
                    </div>

                    <button type="submit" className="settings-button">Update Profile</button>
                </form>
                <button onClick={() => setShowModal(true)} className="delete-button">Delete Account</button>

                <ConfirmationModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    onConfirm={handleDeleteAccount}
                    title="Confirm Account Deletion"
                    body="Are you sure you want to delete your account? This action cannot be undone."
                />

                <ToastContainer autoClose={2500} />
            </div>
        </div>
    );
}

export default SettingsForm;
