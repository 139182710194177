import React, { useState, useEffect, useRef } from "react";
import { UilAnalysis } from "@iconscout/react-unicons";
import Card from './Card';
import {MENTAL_HEALTH_METRICS, PRODUCTIVITY_METRICS, PERSONAL_GROWTH_METRICS, ALL_METRICS, POSITIVE_METRICS, INVERTED_METRICS, POSITIVE_EMOTIONS} from '../Utilities/metricGrouping';
import {SingleMetricDataModel} from '../Utilities/DataModel'

import "../Styles/Cards.css";

import { toast } from 'react-toastify';

import { motion } from "framer-motion";

const Cards = ({userData, categories, plotlyConfig, numDays, dimensions}) => {

  const overall = {
      'Wellbeing': ALL_METRICS
  }

  const indicators = {
      'Mental Health': MENTAL_HEALTH_METRICS,
      'Productivity': PRODUCTIVITY_METRICS,
      'Personal Growth': PERSONAL_GROWTH_METRICS
  }

  // Mental Heatlh
  const indicatorMentalHealth = {
      'Mental Health': MENTAL_HEALTH_METRICS,
  }

  const contrastMetrics = {
      'Positive': POSITIVE_METRICS,
      'Negative': INVERTED_METRICS,
  }

  const multiMetricPosNeg = {
      'Positive Emotions': POSITIVE_EMOTIONS,
      'Negative Emotions': INVERTED_METRICS,
  }

  const individualMetrics = {};
  ALL_METRICS.forEach((metricName) => {
    individualMetrics[metricName] = [metricName];
  });

  const positiveEmotions = {};
  POSITIVE_EMOTIONS.forEach(metricName => {
    positiveEmotions[metricName] = [metricName];
  });

  const negativeEmotions = {};
  INVERTED_METRICS.forEach(metricName => {
    negativeEmotions[metricName] = [metricName];
  });

  // Indicator Productivity
  const indicatorProductivity = {
      'Productivity': PRODUCTIVITY_METRICS,
  }

  const productivityMetrics = {};
  PRODUCTIVITY_METRICS.forEach(metricName => {
    productivityMetrics[metricName] = [metricName];
  });

  // Personal Growth
  const indicatorPersonalGrowth = {
      'Personal Growth': PERSONAL_GROWTH_METRICS
  }

  const personalGrowthMetrics = {};
  PERSONAL_GROWTH_METRICS.forEach(metricName => {
    personalGrowthMetrics[metricName] = [metricName];
  });


  // const dataModel = new SingleMetricDataModel(userData, categories);

  const CardsData = [
    {
      title: "Wellbeing",
      color: {
        background: "linear-gradient(180deg, var(--wellbeing-light) 0%, var(--wellbeing-dark) 100%)",
        boxShadow: "0px 0.5rem 1rem 0px var(--dashboardDarkest)"
      },
      datamodel: new SingleMetricDataModel(userData, Object.values(overall)[0]),
      categories: [
        {title: "Overall Wellbeing", mapping: overall, fill: "tonexty"},
        {title: "Wellbeing Indicators", mapping: indicators, fill: ""},
      ]
    },
    {
      title: "Mental Health",
      color: {
        background: "linear-gradient(180deg, var(--mental-health-light) 0%, var(--mental-health-dark) 100%)",
        boxShadow: "0px 0.5rem 1rem 0px var(--dashboardDarkest)"
      },
      datamodel: new SingleMetricDataModel(userData, Object.values(indicatorMentalHealth)[0]),
      categories: [
        {title: "Mental Health", mapping: indicatorMentalHealth, fill: "tonexty"},
        {title: "Positive vs. Negative Emotions", mapping: contrastMetrics, fill: "tonexty"},
        {title: "Positive Emotions", mapping: positiveEmotions, fill: ""},
        {title: "Negative Emotions", mapping: negativeEmotions, fill: ""},
      ]
    },
    {
      title: "Productivity",
      color: {
        background: "linear-gradient(180deg, var(--productivity-light) 0%, var(--productivity-dark) 100%)",
        boxShadow: "0px 0.5rem 1rem 0px var(--dashboardDarkest)"
      },
      datamodel: new SingleMetricDataModel(userData, Object.values(indicatorProductivity)[0]),
      categories: [
        {title: "Productivity", mapping: indicatorProductivity, fill: "tonexty"},
        {title: "Productivity Indicators", mapping: productivityMetrics, fill: ""},
      ]
    },
    {
      title: "Personal Growth",
      color: {
        background: "linear-gradient(180deg, var(--personal-growth-light) 0%, var(--personal-growth-dark) 100%)",
        boxShadow: "0px 0.5rem 1rem 0px var(--dashboardDarkest)"
      },
      datamodel: new SingleMetricDataModel(userData, Object.values(indicatorPersonalGrowth)[0]),
      categories: [
        {title: "Personal Growth", mapping: indicatorPersonalGrowth, fill: "tonexty"},
        {title: "Personal Growth Indicators", mapping: personalGrowthMetrics, fill: ""},
      ]
    }
  ];

  let filteredCards = CardsData.filter(card => categories.includes(card.title));

  return (
    <div className="cards-container">
      {filteredCards.map((card, id) => {
        return (
            <Card
              title={card.title}
              color={card.color}

              pointsToday={card.datamodel.pointsToday}
              dayScore={card.datamodel.todayScore}
              dayStandardDeviation={card.datamodel.todaySD}
              dayMax={card.datamodel.calculateTodaysMaxScore()}
              dayMin={card.datamodel.calculateTodaysMinScore()}
              date={card.datamodel.todayDate.toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric'
              })}
              baselineScore={card.datamodel.monthlyBaseline}

              categories={card.categories}

              userData={userData}
              plotlyConfig={plotlyConfig}
              numDays={numDays}
              dimensions={dimensions}

            />
      )
      })}
    </div>
  );
};

export default Cards;
