import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/SearchBar.css';
import {searchUser} from '../Firebase/readUserDetails';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { UilSearch, UilTimes } from "@iconscout/react-unicons";
import { auth, db } from '../Firebase/firebaseConfig';
import { doc, setDoc, getDocs, collection, query, orderBy, limit, deleteDoc, where, updateDoc, writeBatch } from 'firebase/firestore';


function SearchBar() {
    const [searchUsername, setSearchUsername] = useState('');
    const [pastSearches, setPastSearches] = useState([]);
    const navigate = useNavigate();
    const userId = auth.currentUser.uid;

    const handleSearch = async () => {
        console.log("searching for", searchUsername);
        const userDoc = await searchUser(searchUsername);
        console.log(userDoc);
        if (userDoc) {
          const added = await saveOrUpdateSearch(searchUsername);
          if (added) {
              setPastSearches([...pastSearches, searchUsername]); // Update past searches list only if new
          }
          navigate(`/user-profile/${searchUsername}`);
        } else {
          toast.error(`User ${searchUsername} not found!`);
        }
    };

    const handleSearchWithUsername = async (username) => {
      console.log("handleSearchWithUsername", username);
      setSearchUsername(username);
      console.log("searching for", username);
      const userDoc = await searchUser(username);
      console.log(userDoc);
      if (userDoc) {
        const added = await saveOrUpdateSearch(username);
        if (added) {
            setPastSearches([...pastSearches, username]); // Update past searches list only if new
        }
        navigate(`/user-profile/${username}`);
      } else {
        toast.error(`User ${username} not found!`);
      }
    }

    const clearAllSearches = async () => {
        const searchesRef = collection(db, 'users', userId, 'searches');
        const querySnapshot = await getDocs(searchesRef);
        const batch = writeBatch(db);
        querySnapshot.forEach((doc) => {
            batch.delete(doc.ref); // Schedule each document for deletion
        });
        await batch.commit(); // Commit the batch
        setPastSearches([]); // Clear the local state
    };

    useEffect(() => {
        fetchSearchHistory();
    }, []);

    const saveOrUpdateSearch = async (searchText) => {
        const searchesRef = collection(db, 'users', userId, 'searches');
        const q = query(searchesRef, where('searchText', '==', searchText));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
                updateDoc(doc.ref, { time: new Date() }); // Update the timestamp if entry exists
            });
            return false;
        } else {
            const newSearchRef = doc(collection(db, 'users', userId, 'searches'));
            await setDoc(newSearchRef, { searchText, time: new Date() }); // Create a new entry if not found
            return true;
        }
    };

    const fetchSearchHistory = async () => {
        const searchesRef = collection(db, 'users', userId, 'searches');
        const q = query(searchesRef, orderBy('time', 'desc'), limit(10));
        const querySnapshot = await getDocs(q);
        const searches = querySnapshot.docs.map(doc => doc.data().searchText);
        setPastSearches(searches);
    };

    const deleteSearch = async (searchText) => {
        const searchesRef = collection(db, 'users', userId, 'searches');
        const q = query(searchesRef, where('searchText', '==', searchText));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
            await deleteDoc(doc.ref);
        });
        setPastSearches(pastSearches.filter(search => search !== searchText));
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };


    return (
      <>
        <div className="search-bar">
            <input
                type="text"
                placeholder="Search username..."
                value={searchUsername}
                onChange={(e) => setSearchUsername(e.target.value)}
                onKeyPress={handleKeyPress}
                className="search-input"
            />
            <UilSearch className="search-icon" onClick={handleSearch} />
        </div>
        <hr className='search-bar-history-sep'/>
        <div className="search-history-container">
          <div className="search-history-header">
            <span>Recent</span>
            {pastSearches.length > 0? <span onClick={clearAllSearches}>Clear All</span>: <></>}
          </div>
          <div className="search-history-scroll">
          {pastSearches.length > 0? pastSearches.map((search) => {
            return (
              <div className="search-history-row-item" onClick={() => handleSearchWithUsername(search)}>
                <span>@{search}</span>

                <UilTimes onClick={(e)=>{e.stopPropagation(); deleteSearch(search)}} className="search-history-row-delete"/>
              </div>
            )
          }): <span className="search-history-empty-text">No recent searches!</span>}

          </div>

        </div>
        </>
    );
}

export default SearchBar;
