import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { auth } from '../Firebase/firebaseConfig';
import { UilChart, UilSpinner } from "@iconscout/react-unicons";
import "../Styles/Dashboard.css";
import "../Styles/Insights.css";
import { motion } from "framer-motion";
import SideBar from '../Components/SideBar';
import { SingleMetricDataModel } from '../Utilities/DataModel';
import { ALL_METRICS, MENTAL_HEALTH_METRICS, PRODUCTIVITY_METRICS, PERSONAL_GROWTH_METRICS, INVERTED_METRICS } from '../Utilities/metricGrouping';
import { fetchEntriesInRange } from '../Firebase/fetchEntriesInRange';
import ReactMarkdown from 'react-markdown';

const Insights = () => {
  const [insightsData, setInsightsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

  const navigate = useNavigate();

  const interpretCorrelation = (correlation) => {
    if (correlation > 0.7) {
      return "There's a **strong positive** relationship. ";
    } else if (correlation > 0.3) {
      return "There's a **moderate positive** relationship. ";
    } else if (correlation > -0.3) {
      return "There's **little to no** relationship. ";
    } else if (correlation > -0.7) {
      return "There's a **moderate negative** relationship. ";
    } else {
      return "There's a **strong negative** relationship. ";
    }
  };

  const interpretImpact = (correlation, impact, firstMetric, secondMetric) => {
    if (Math.abs(correlation) <= 0.3) {
      return `- Since the relationship is weak, the changes in **${firstMetric}** are **not reliably associated** with changes in **${secondMetric}**.`;
    }

    const absImpact = Math.abs(impact);
    if (absImpact === 0) {
      return `- Changes in **${firstMetric}** show **no association** with changes in **${secondMetric}**.`;
    } else if (absImpact < 1) {
      return `- On average, a 1-point change in **${firstMetric}** is associated with a **${absImpact.toFixed(2)}-point ${impact > 0 ? "increase" : "decrease"}** in **${secondMetric}**.`;
    } else if (absImpact === 1) {
      return `- On average, changes in **${firstMetric}** are associated with **proportional ${impact > 0 ? "increases" : "decreases"}** in **${secondMetric}**.`;
    } else {
      return `- On average, a 1-point change in **${firstMetric}** is associated with a **${absImpact.toFixed(2)}-point ${impact > 0 ? "increase" : "decrease"}** in **${secondMetric}**. This suggests that changes in **${firstMetric}** are associated with **larger changes** in **${secondMetric}**.`;

    }
  };

  const getMostImpactfulMetrics = (models, metric, nMetrics = 3, correlationThreshold = 0.7) => {
    let keyImpactFactors = Object.entries(models)
      .filter(([_, model]) => model.correlation >= correlationThreshold)
      .sort((a, b) => b[1].forwardImpact*b[1].correlation - a[1].forwardImpact*a[1].correlation)
      .slice(0, nMetrics);

    const keyImpactFactorsInterpretation = keyImpactFactors.map(([metricName, model]) => {
      let interpretation = `- **${metricName}** has a significant impact on your **${metric}** score.\n`;
      interpretation += `  ${interpretCorrelation(model.correlation)}\n`;
      interpretation += `  ${interpretImpact(model.correlation, model.forwardImpact, metricName, metric)}`;
      return interpretation;
    });

    // let strongNegativeCorrelations = Object.entries(models)
    //   .filter(([_, model]) => model.correlation <= -correlationThreshold)
    //   .sort((a, b) => Math.abs(b[1].forwardImpact) * Math.abs(b[1].correlation) - Math.abs(a[1].forwardImpact) * Math.abs(a[1].correlation))
    //   .slice(0, nMetrics);

    // const negativeCorrelationsInterpretation = strongNegativeCorrelations.map(([metricName, model]) => {
    //   let interpretation = `- **${metricName}** has a significant negative impact on your **${metric}** score.\n`;
    //   interpretation += `  ${interpretCorrelation(model.correlation)}\n`;
    //   interpretation += `  ${interpretImpact(model.correlation, model.forwardImpact, metricName, metric)}`;
    //   return interpretation;
    // });

    return {
      title: `Most Impactful Metrics on ${metric}`,
      // "Positive:\n" + 
      description: keyImpactFactorsInterpretation.join("\n") + "\n\n"
      //  + 
                  //  + (negativeCorrelationsInterpretation.length > 0 ? 
                  //   negativeCorrelationsInterpretation.join("\n") : "")
    };
  }

  const calculateCorrelation = (x, y) => {
    try {
      if (x.length !== y.length) {
        throw new Error("Arrays must have the same length");
      }

      const n = x.length;

      if (n === 0) {
        throw new Error("Arrays must not be empty");
      }

      let sumX = 0, sumY = 0, sumXY = 0, sumX2 = 0, sumY2 = 0;

      for (let i = 0; i < n; i++) {
        if (typeof x[i] !== 'number' || typeof y[i] !== 'number') {
          throw new Error("Arrays must contain only numbers");
        }

        sumX += x[i];
        sumY += y[i];
        sumXY += x[i] * y[i];
        sumX2 += x[i] * x[i];
        sumY2 += y[i] * y[i];
      }

      const numerator = n * sumXY - sumX * sumY;
      const denominator = Math.sqrt((n * sumX2 - sumX * sumX) * (n * sumY2 - sumY * sumY));

      if (denominator === 0) {
        return 0; // No correlation when there's no variation
      }

      return numerator / denominator;
    } catch (error) {
      console.error("Error in calculateCorrelation:", error.message);
      return null; // or any other value to indicate an error
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!auth.currentUser) {
        console.log("No user logged in");
        navigate('/login');
        return;
      }

      const currentUser = auth.currentUser;

      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(endDate.getDate() - 30);

      const data = await fetchEntriesInRange(startDate, endDate);
      console.log(currentUser.displayName, data);
      data.sort((a, b) => new Date(a.time) - new Date(b.time));
      setUserData(data);

      setIsLoading(true);
      try {
        const allMetricsModel = new SingleMetricDataModel(data, ALL_METRICS);
        const mentalHealthModel = new SingleMetricDataModel(data, MENTAL_HEALTH_METRICS);
        const productivityModel = new SingleMetricDataModel(data, PRODUCTIVITY_METRICS);
        const personalGrowthModel = new SingleMetricDataModel(data, PERSONAL_GROWTH_METRICS);

        let individualMetricsModels = {};
        for (let metric of ALL_METRICS) {
          individualMetricsModels[metric] = new SingleMetricDataModel(data, [metric]);
        }
        
        const mentalHealthOnProductivity = mentalHealthModel.calculateImpactOn(productivityModel);
        const productivityOnMentalHealth = productivityModel.calculateImpactOn(mentalHealthModel);
        const personalGrowthOnMentalHealth = personalGrowthModel.calculateImpactOn(mentalHealthModel);
        const mentalHealthOnPersonalGrowth = mentalHealthModel.calculateImpactOn(personalGrowthModel);

        let individualMetricImpactsOnOverall = {};
        for (let metric of ALL_METRICS) {
          let model = individualMetricsModels[metric].calculateImpactOn(allMetricsModel);
          individualMetricImpactsOnOverall[metric] = model;
        }

        let individualMetricImpactsOnMentalHealth = {};
        for (let metric of ALL_METRICS) {
          let model = individualMetricsModels[metric].calculateImpactOn(mentalHealthModel);
          individualMetricImpactsOnMentalHealth[metric] = model;
        }

        let individualMetricImpactsOnProductivity = {};
        for (let metric of ALL_METRICS) {
          let model = individualMetricsModels[metric].calculateImpactOn(productivityModel);
          individualMetricImpactsOnProductivity[metric] = model;
        }

        let individualMetricImpactsOnPersonalGrowth = {};
        for (let metric of ALL_METRICS) {
          let model = individualMetricsModels[metric].calculateImpactOn(personalGrowthModel);
          individualMetricImpactsOnPersonalGrowth[metric] = model;
        }

        const mostImpactfulMetricsOnOverall = getMostImpactfulMetrics(individualMetricImpactsOnOverall, "Overall Wellbeing");
        const mostImpactfulMetricsOnMentalHealth = getMostImpactfulMetrics(individualMetricImpactsOnMentalHealth, "Mental Health");
        const mostImpactfulMetricsOnProductivity = getMostImpactfulMetrics(individualMetricImpactsOnProductivity, "Productivity");
        const mostImpactfulMetricsOnPersonalGrowth = getMostImpactfulMetrics(individualMetricImpactsOnPersonalGrowth, "Personal Growth");


        const highestScoringDays = allMetricsModel.calculateHighestScoringDaysOfWeek();
        const highestDay = highestScoringDays[0];
        const lowestDay = highestScoringDays[highestScoringDays.length - 1];

        const weekdayScores = highestScoringDays.filter(day => ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(day.day));
        const weekendScores = highestScoringDays.filter(day => ['Saturday', 'Sunday'].includes(day.day));
        const avgWeekdayScore = weekdayScores.reduce((sum, day) => Number(sum) + Number(day.score), 0) / weekdayScores.length;
        const avgWeekendScore = weekendScores.reduce((sum, day) => Number(sum) + Number(day.score), 0) / weekendScores.length;

        const weekdayWeekendComparison = avgWeekdayScore > avgWeekendScore
          ? `- On average, your scores are **higher on weekdays** (${avgWeekdayScore.toFixed(2)}) compared to weekends (${avgWeekendScore.toFixed(2)}).`
          : `- On average, your scores are **higher on weekends** (${avgWeekendScore.toFixed(2)}) compared to weekdays (${avgWeekdayScore.toFixed(2)}).`;

        const happiestHours = allMetricsModel.calculateHighestScoringHours();
        const happiestHour = happiestHours[0];
        const leastHappyHour = happiestHours[happiestHours.length - 1];

        const mostProductiveHours = productivityModel.calculateHighestScoringHours();
        const mostProductiveHour = mostProductiveHours[0];
        const leastProductiveHour = mostProductiveHours[mostProductiveHours.length - 1];

        const convertToAMPM = (hour) => {
          const ampm = hour >= 12 ? 'PM' : 'AM';
          const adjustedHour = hour % 12 || 12;
          return `${adjustedHour} ${ampm}`;
        };

        happiestHour.hour = convertToAMPM(happiestHour.hour);
        leastHappyHour.hour = convertToAMPM(leastHappyHour.hour);
        mostProductiveHour.hour = convertToAMPM(mostProductiveHour.hour);
        leastProductiveHour.hour = convertToAMPM(leastProductiveHour.hour);

        const insights = [
          { 
            id: 1, 
            title: "Weekly Peaks & Valleys", 
            description: `- Your **best day** is typically **${highestDay.day}** with an average score of **${highestDay.score}**.\n- You tend to score **lowest** on **${lowestDay.day}** with an average score of **${lowestDay.score}**.\n${weekdayWeekendComparison}` 
          },
          { 
            id: 2, 
            title: "Happy Hours", 
            description: `- Your mood is **highest** at **${happiestHour.hour}** with an average score of **${happiestHour.score}**.\n- It is **lowest** at **${leastHappyHour.hour}** with an average score of **${leastHappyHour.score}**.`
          },
          { 
            id: 3, 
            title: "Time to Focus", 
            description: `- You are usually **most productive** at **${mostProductiveHour.hour}** with an average score of **${mostProductiveHour.score}**.\n- You are usually **least productive** at **${leastProductiveHour.hour}** with an average score of **${leastProductiveHour.score}**.` 
          },
          (() => {
            const models = {
              'overall': allMetricsModel,
              'mental health': mentalHealthModel,
              'productivity': productivityModel,
              'personal growth': personalGrowthModel
            };
            
            let descriptions = [];
            
            for (const [modelName, model] of Object.entries(models)) {
              const breakImpact = model.calculateBreakImpact();
              let totalImpact = 0;
              let breakCount = 0;
              
              breakImpact.forEach(period => {
                if (period.previousScore !== null && period.breakLength > 0) {
                  const impact = period.currentScore - period.previousScore;
                  totalImpact += impact;
                  breakCount++;
                }
              });

              const averageImpact = breakCount > 0 ? totalImpact / breakCount : 0;
              const impactDescription = averageImpact > 0 ? "positive" : "negative";
              const magnitude = Math.abs(averageImpact);

              let interpretation;
              if (magnitude < 5) {
                interpretation = "insignificant";
              } else if (magnitude < 10) {
                interpretation = "moderate";
              } else {
                interpretation = "significant";
              }

              if (magnitude >= 5) {
                descriptions.push(`- On average, taking breaks has a ${interpretation} ${impactDescription} impact on your ${modelName}.\n- After a break, your ${modelName} score typically changes by ${averageImpact.toFixed(2)} points.`);
              }
            }
            
            if (descriptions.length > 0) {
              return {
                id: 3,
                title: "Impact of Breaks",
                description: descriptions.join('\n')
              };
            }
            
            return null;
          })(),
          (() => {
            const models = {
              'overall': allMetricsModel,
              'mental health': mentalHealthModel,
              'productivity': productivityModel,
              'personal growth': personalGrowthModel
            };
            
            let descriptions = [];
            
            for (const [modelName, model] of Object.entries(models)) {
              const relationship = model.calculateActivityScoreRelationship();
              const correlation = calculateCorrelation(
                relationship.map(r => r.numberOfDataPoints),
                relationship.map(r => r.score)
              );
              
              if (correlation === null || Math.abs(correlation) < 0.2) {
                continue;
              }
              
              const direction = correlation > 0 ? "higher" : "lower";
              const strength = Math.abs(correlation) < 0.4 ? "slightly" : Math.abs(correlation) < 0.6 ? "moderately" : "strongly";
              const interpretation = `As you are more active, you tend to score ${strength} ${direction} in ${modelName}`;
              descriptions.push(`- ${interpretation}.`);
            }
            
            if (descriptions.length > 0) {
              return {
                id: 5,
                title: "Activity Level and Scores",
                description: descriptions.join('\n')
              };
            }
            
            return null;
          })(),
          { 
            id: 4, 
            title: Math.abs(mentalHealthOnProductivity.correlation) < 0.3 ? "Mental Health & Productivity" : "Mental Health Impact on Productivity", 
            description: `- ${interpretCorrelation(mentalHealthOnProductivity.correlation)}\n${interpretImpact(mentalHealthOnProductivity.correlation, mentalHealthOnProductivity.forwardImpact, "mental health", "productivity")}`
          },
          ...(Math.abs(mentalHealthOnProductivity.correlation) >= 0.3 ? [{ 
            id: 5, 
            title: "Productivity Impact on Mental Health", 
            description: `- ${interpretCorrelation(productivityOnMentalHealth.correlation)}\n${interpretImpact(productivityOnMentalHealth.correlation, productivityOnMentalHealth.forwardImpact, "productivity", "mental health")}`
          }] : []),
          ...(Math.abs(personalGrowthOnMentalHealth.correlation) >= 0.3 ? [{
            id: 7,
            title: "Mental Health Impact on Personal Growth",
            description: `- ${interpretCorrelation(mentalHealthOnPersonalGrowth.correlation)}\n${interpretImpact(mentalHealthOnPersonalGrowth.correlation, mentalHealthOnPersonalGrowth.forwardImpact, "mental health", "personal growth")}`
          }] : []),
          {
            id: 6,
            title: Math.abs(personalGrowthOnMentalHealth.correlation) < 0.3 ? "Personal Growth & Mental Health" : "Personal Growth Impact on Mental Health",
            description: `- ${interpretCorrelation(personalGrowthOnMentalHealth.correlation)}\n${interpretImpact(personalGrowthOnMentalHealth.correlation, personalGrowthOnMentalHealth.forwardImpact, "personal growth", "mental health")}`
          },
          {
            id: 8,
            title: mostImpactfulMetricsOnOverall.title,
            description: mostImpactfulMetricsOnOverall.description
          },
          {
            id: 9,
            title: mostImpactfulMetricsOnMentalHealth.title,
            description: mostImpactfulMetricsOnMentalHealth.description
          },
          {
            id: 10,
            title: mostImpactfulMetricsOnProductivity.title,
            description: mostImpactfulMetricsOnProductivity.description
          },
          {
            id: 11,
            title: mostImpactfulMetricsOnPersonalGrowth.title,
            description: mostImpactfulMetricsOnPersonalGrowth.description
          }
        ];

        setInsightsData(insights.filter(insight => insight !== null));
      } catch (error) {
        console.error("Error fetching insights data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [navigate]);

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <div className="dashboard-split">
          <SideBar activeItem="Insights"/>
          <div className="dashboard-main-content">
            <motion.div 
              className="insights-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="insights-title">
                    <UilChart size="28" className="insights-icon" /> Insights
              </h2>
              {isLoading ? (
                <div className="loading-spinner">
                  <UilSpinner size="40" color="white" className="spinner-icon" />
                </div>
              ) : insightsData.length > 0 ? (
                <div className="insights-list">
                  {insightsData.map((insight) => (
                    <div key={insight.id} className="insight-item">
                      <div className="insight-details">
                        <span className="insight-title">{insight.title}</span>
                        <ReactMarkdown className="insight-description">{insight.description}</ReactMarkdown>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="insights-empty-message">
                  No insights available at the moment. Keep using the app to generate insights!
                </p>
              )}
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Insights;