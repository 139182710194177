import { doc, getDoc, setDoc, getDocs, deleteDoc, updateDoc, collection, query, where, orderBy, limit, startAfter } from 'firebase/firestore';
import { db } from './firebaseConfig';

const checkFollowStatus = async (firestore, currentUserUid, targetUsername) => {
  try {
    const targetUserRef = doc(firestore, 'users', targetUsername);
    const followerRef = doc(targetUserRef, 'followers', currentUserUid);
    const followerDoc = await getDoc(followerRef);

    if (followerDoc.exists()) {
      return followerDoc.data().status;
    } else {
      return null; // No entry exists for this follower
    }
  } catch (error) {
    console.error('Error checking follow status:', error);
    return null;
  }
}

const createFollowEntry = async (firestore, currentUserUid, targetUserId) => {
  try {
    console.log(`Creating follow entry for ${currentUserUid} to follow ${targetUserId}`);
    const targetUserRef = doc(firestore, `users/${targetUserId}/followers`, currentUserUid);
    const currentUserFollowingRef = doc(firestore, `users/${currentUserUid}/following`, targetUserId);
    
    const isPrivateDoc = await getDoc(doc(firestore, `users/${targetUserId}`));
    const isPrivate = isPrivateDoc.data().isPrivate;
    const status = isPrivate ? 'requested' : 'accepted';

    await setDoc(targetUserRef, { status }, { merge: true });
    await setDoc(currentUserFollowingRef, { status }, { merge: true });
    
    console.log('Follow request successfully sent!');
    return true;
  } catch (error) {
    console.error('Error creating follow entry:', error);
    return false;
  }
}

const deleteFollowEntry = async (firestore, currentUserUid, targetUserId) => {
  try {
    const targetUserFollowerRef = doc(firestore, `users/${targetUserId}/followers`, currentUserUid);
    const currentUserFollowingRef = doc(firestore, `users/${currentUserUid}/following`, targetUserId);

    await deleteDoc(targetUserFollowerRef);
    await deleteDoc(currentUserFollowingRef);
    return true;
  } catch (error) {
    console.error('Error deleting follow entry:', error);
    return false;
  }
}

const respondToFollowRequest = async (firestore, recipientUserId, followerUserId, status) => {
  try {
    if (status !== 'accepted' && status !== 'rejected') {
      throw new Error('Invalid status. Status must be "accepted" or "rejected".');
    }

    const followerRef = doc(firestore, `users/${recipientUserId}/followers`, followerUserId);
    const followingRef = doc(firestore, `users/${followerUserId}/following`, recipientUserId);
    
    if (status === 'accepted') {
      await updateDoc(followerRef, { status });
      await updateDoc(followingRef, { status });
    } else {
      await deleteDoc(followerRef);
      await deleteDoc(followingRef);
    }
    
    return true;
  } catch (error) {
    console.error('Error responding to follow request:', error);
    return false;
  }
}

const getFollowersByStatus = async (firestore, userId, status) => {
  try {
    const followersRef = collection(firestore, `users/${userId}/followers`);
    const q = query(followersRef, where("status", "==", status));
    const querySnapshot = await getDocs(q);
    const followers = [];

    querySnapshot.forEach(doc => {
      followers.push({ ...doc.data(), id: doc.id });
    });

    return followers;
  } catch (error) {
    console.error('Error retrieving followers by status:', error);
    return [];
  }
}

const getFollowersAndFollowing = async (userId, lastFollowerId = null, lastFollowingId = null, pageSize = 100) => {
  try {
    const followersRef = collection(db, `users/${userId}/followers`);
    const followingRef = collection(db, `users/${userId}/following`);

    let followersQuery = query(followersRef, where("status", "==", "accepted"), limit(pageSize));
    let followingQuery = query(followingRef, where("status", "==", "accepted"), limit(pageSize));

    if (lastFollowerId) {
      followersQuery = query(followersQuery, startAfter(doc(followersRef, lastFollowerId)));
    }
    if (lastFollowingId) {
      followingQuery = query(followingQuery, startAfter(doc(followingRef, lastFollowingId)));
    }

    const [followersSnapshot, followingSnapshot] = await Promise.all([
      getDocs(followersQuery),
      getDocs(followingQuery)
    ]);

    const followers = followersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    const following = followingSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    const lastVisible = {
      followers: followers.length > 0 ? followers[followers.length - 1].id : null,
      following: following.length > 0 ? following[following.length - 1].id : null
    };

    console.log("followers: ", followers);
    console.log("following: ", following);

    return { followers, following, lastVisible };
  } catch (error) {
    console.error('Error retrieving followers and following:', error);
    return { followers: [], following: [], lastVisible: { followers: null, following: null } };
  }
}

export {checkFollowStatus, createFollowEntry, deleteFollowEntry, respondToFollowRequest, getFollowersByStatus, getFollowersAndFollowing};