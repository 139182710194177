import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, query, where, collection, getDocs } from 'firebase/firestore';
import { db } from '../Firebase/firebaseConfig';
import { useAuth } from '../Context/AuthContext';
import { getUIDFromUsername, getQuerySnapFromUsername } from '../Firebase/readUserDetails';
import { checkFollowStatus, createFollowEntry, deleteFollowEntry } from '../Firebase/followUtils';
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from '../Firebase/firebaseConfig';
import defaultUserImage from '../Resources/profile-pic.png';
import '../Styles/PrivateUser.css';
import '../Styles/Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import Loading from '../Pages/Loading';
import SideBar from '../Components/SideBar';

function PrivateUser() {
  const { username } = useParams();
  const { currentUser } = useAuth();
  const [userDetails, setUserDetails] = useState(null);
  const [followStatus, setFollowStatus] = useState(null);
  const [userFollowsBack, setUserFollowsBack] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState(null);


  const toggleFollowRequest = async () => {
      const userId = await getUIDFromUsername(username);

      if (followStatus === 'requested') {
          const result = await deleteFollowEntry(db, currentUser.uid, userId);
          if (result) {
              setFollowStatus(null);
              console.log('Follow request retracted!');
          } else {
              console.log('Failed to retract follow request');
          }
      } else {
          const result = await createFollowEntry(db, currentUser.uid, userId);
          if (result) {
              setFollowStatus('requested');
              console.log('Follow request sent!');
          } else {
              console.log('Failed to send follow request');
          }
      }
  };

  useEffect(() => {
    const fetchProfilePic = (userId) => {
      console.log("profilePictures", userId);
      const profilePicRef = ref(storage, `profilePictures/${userId}`);
      getDownloadURL(profilePicRef)
          .then((url) => {
              setProfilePicUrl(url);
          })
          .catch(() => {
              console.log('No profile picture found, using default');
              setProfilePicUrl(defaultUserImage); // Set default profile picture
          });
    }

    async function fetchData() {
      const querySnapshot = await getQuerySnapFromUsername(username);
      if (!querySnapshot) {
        return;
      }

      const userData = querySnapshot.docs[0].data();
      const userId = querySnapshot.docs[0].id;
      setUserDetails(userData);
      fetchProfilePic(userId);

      // Check follow status using UID
      const status = await checkFollowStatus(db, currentUser.uid, userId);
      setFollowStatus(status);

      // Check if the private user follows the current user
      const reverseStatus = await checkFollowStatus(db, userId, currentUser.uid);
      setUserFollowsBack(reverseStatus === 'accepted');
    }

    fetchData();
    fetchProfilePic();


  }, [username, currentUser]);

  const sendFollowRequest = async () => {
    const userId = await getUIDFromUsername(username);
    console.log("userId", userId)
    const result = await createFollowEntry(db, currentUser.uid, userId);
    if (result) {
      setFollowStatus('requested');
      console.log('Follow request sent!');
    } else {
      console.log('Failed to send follow request');
    }
  };

  if (!userDetails) {
    return <Loading/>;
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <div className="dashboard-split">
          <SideBar/>
          <div className="background-container">
            <div className="private-user-container">
              <header className="private-user-header">
                <img src={profilePicUrl} alt="Profile" className="private-user-profile-pic" />
                <div className="private-user-info">
                  <h2>{userDetails.username}</h2>
                  <h3>{userDetails.firstName} {userDetails.lastName}</h3>
                </div>
              </header>
              <hr className="seperator"/>
              <div className="private-user-body">
                <FontAwesomeIcon icon={faLock} className="private-user-fa-lock" />
                <div className="private-user-text">
                  <p className="fa-lock-title">This account is private.</p>
                  <p className="fa-lock-subtitle">Follow them to see their daily updates.</p>
                </div>
              </div>
              <div>

              <div className="button-container-bottom">
                {followStatus === 'requested' ? (
                  <button onClick={toggleFollowRequest} className="btn-requested">Requested</button>
                ) : followStatus === 'accepted' ? (
                  <p>You are following this user.</p>
                ) : (
                  <button onClick={toggleFollowRequest} className="btn-follow">Follow</button>
                )}
              </div>
                {userFollowsBack && <p>This user follows you back.</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivateUser;
