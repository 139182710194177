import { db, auth } from './firebaseConfig';
import { collection, query, where, orderBy, getDocs, Timestamp, limit, startAfter } from 'firebase/firestore';
import {getUIDFromUsername} from './readUserDetails';

const BATCH_SIZE = 1000; // Adjust the batch size as needed

export const fetchEntriesInRange = async (startDate, endDate, convertToLocalTime = true, username = null, useFullDayRange = true) => {
    const user = auth.currentUser;
    if (!user) {
        console.log("No user is logged in.");
        return;
    }
    let userId = username === null ? user.uid : await getUIDFromUsername(username);

    let startTimestamp, endTimestamp;

    if (useFullDayRange) {
        // Convert the start and end dates to UTC at the start of the day for startDate and end of the day for endDate
        const start = new Date(startDate);
        start.setUTCHours(0, 0, 0, 0); // Set hours to 00:00 of the start date
        const end = new Date(endDate);
        end.setUTCHours(23, 59, 59, 999); // Set to the last millisecond of the end date

        startTimestamp = Timestamp.fromDate(start);
        endTimestamp = Timestamp.fromDate(end);
    } else {
        // Use the provided startDate and endDate directly
        startTimestamp = Timestamp.fromDate(startDate);
        endTimestamp = Timestamp.fromDate(endDate);
    }

    console.log("Fetching from", startTimestamp.toDate(), "to", endTimestamp.toDate());

    // Reference to the user's entries collection
    const entriesRef = collection(db, 'users', userId, 'entries');

    let lastVisible = null;
    let allEntries = [];

    try {
        while (true) {
            let entriesQuery = query(
                entriesRef,
                where("time", ">=", startTimestamp),
                where("time", "<=", endTimestamp),
                orderBy("time", "asc"),
                limit(BATCH_SIZE)
            );

            if (lastVisible) {
                entriesQuery = query(entriesQuery, startAfter(lastVisible));
            }

            const querySnapshot = await getDocs(entriesQuery);

            console.log("Batch Size:", querySnapshot.docs.length);

            if (querySnapshot.empty) {
                break;
            }

            querySnapshot.forEach(doc => {
                const data = doc.data();
                const time = convertToLocalTime
                    ? new Date(data.time.toDate().getTime())
                    : data.time.toDate();
                allEntries.push({
                    ...data,
                    time: time,
                    id: doc.id,
                    goals: goalsToDict(data.goals) // Convert array of goals to dictionary
                });
            });

            lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        }

        console.log("Fetched entries:", allEntries);
        return allEntries;
    } catch (error) {
        console.error("Error fetching entries:", error);
        return [];
    }
};


// Helper function to convert goals array to dictionary format
function goalsToDict(goals) {
    const goalDict = {};
    goals.forEach(goal => {
        goalDict[goal.metric] = goal.prediction;
    });
    return goalDict;
}