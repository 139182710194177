import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import '../Styles/LandingPage.css';
import Icon from "../Resources/icon.png";
import handleDownload from "../Firebase/handleDownload";
import { UilLinux, UilApple, UilWindows, UilTimes } from "@iconscout/react-unicons";
import { addDoc } from 'firebase/firestore';
import { emailsRef } from '../Firebase/firebaseConfig';
import handlePageHit from '../Firebase/handlePageHit';

const LandingPage = () => {
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const [email, setEmail] = useState('');
    const [subscriptionStatus, setSubscriptionStatus] = useState('');
    const popupRef = useRef(null);

    useEffect(() => {
        handlePageHit("landingPageHit");
        const hasVisited = localStorage.getItem('hasVisited');
        if (!hasVisited) {
            setTimeout(() => {
                setShowPopup(true);
                localStorage.setItem('hasVisited', 'true');
                handlePageHit();
            }, 3000);
        }

        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowPopup(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleNavigation = (path) => {
        navigate(path);
    };

    const handleSubscribe = async (e) => {
        e.preventDefault();

        try {
            // Always add a new document, regardless of whether the email exists
            await addDoc(emailsRef, {
                email: email,
                status: "subscribed",
                createdAt: new Date()
            });
            setSubscriptionStatus('Subscribed successfully!');
        } catch (error) {
            console.error("Error subscribing: ", error);
            setSubscriptionStatus('Error occurred. Please try again.');
        }

        setTimeout(() => {
            setShowPopup(false);
            setEmail('');
            setSubscriptionStatus('');
        }, 2000);
    };

    return (
        <div className="landing-page">
            <header className="landing-header">
                <div className="logo-container" onClick={() => handleNavigation('/')}>
                    <img src={Icon} alt="DeepKeys Logo" className="logo" />
                    <span className="logo-text">DeepKeys</span>
                </div>
                <div className="header-buttons">
                    <button onClick={() => handleNavigation('/faq')} className="nav-button">FAQ</button>
                    <button onClick={() => handleNavigation('/login')} className="nav-button">Login</button>
                </div>
            </header>
            
            <div className="content">
                <div className="content-left">
                    <h1>Mental Health Monitoring</h1>
                    <p>Unlocking insights from your devices to enhance mental wellness and boost productivity.</p>
                    <div className="buttons">
                        <a className="download-button" onClick={handleDownload}>
                            {window.navigator.userAgent.indexOf("Linux") !== -1 && <UilLinux className="os-icon" />}
                            {window.navigator.userAgent.indexOf("Mac OS") !== -1 && <UilApple className="os-icon" />}
                            {window.navigator.userAgent.indexOf("Windows") !== -1 && <UilWindows className="os-icon" />}
                            {window.navigator.userAgent.indexOf("Linux") !== -1 || 
                             window.navigator.userAgent.indexOf("Mac OS") !== -1 || 
                             window.navigator.userAgent.indexOf("Windows") !== -1 
                                ? "Download the App"
                                : "Download Desktop App"}
                        </a>
                    </div>
                </div>
                <div className="content-right">
                    <div className="content-right">
                        <div className="video-container">
                            <iframe
                                className="demo-video"
                                src="https://www.youtube.com/embed/Dspk9qjqibQ?autoplay=1&mute=1&loop=1&playlist=Dspk9qjqibQ&controls=0&showinfo=0&rel=0&modestbranding=1&disablekb=1&iv_load_policy=3&start=1&end=105"
                                title="DeepKeys Demo Video"
                                frameBorder="0"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                style={{pointerEvents: 'none'}}
                            ></iframe>
                            <div className="video-overlay" onClick={() => window.open('https://www.youtube.com/watch?v=Dspk9qjqibQ', '_blank')}></div>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="landing-footer">
                <div className="footer-links">
                    <button>© DeepKeys LLC 2023-2024</button>
                    <button onClick={() => handleNavigation('/privacy-policy')}>Privacy Policy</button>
                    <button onClick={() => handleNavigation('/eula')}>EULA</button>
                    <button onClick={() => handleNavigation('/terms-of-use')}>Terms of Use</button>
                    <button onClick={() => handleNavigation('/data-request')}>Data Request</button>
                    <button onClick={() => handleNavigation('/disclaimer')}>Disclaimer</button>
                    <button 
                        onClick={() => window.location.href = 'mailto:support@deepkeys.ai'}
                        onMouseEnter={(e) => {
                            const tooltip = document.createElement('div');
                            tooltip.textContent = 'mailto:support@deepkeys.ai';
                            tooltip.style.position = 'fixed';
                            tooltip.style.bottom = '10px';
                            tooltip.style.left = '10px';
                            tooltip.style.color = 'white';
                            tooltip.style.fontSize = '12px';
                            document.body.appendChild(tooltip);
                            e.target.addEventListener('mouseleave', () => tooltip.remove());
                        }}
                    >
                        Contact Support
                    </button>
                </div>
            </footer>

            <AnimatePresence>
                {showPopup && (
                    <motion.div 
                        className="popup-overlay"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <motion.div 
                            className="popup" 
                            ref={popupRef}
                            initial={{ scale: 0.5, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.5, opacity: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <UilTimes className="close-icon" onClick={() => setShowPopup(false)} />
                            <div className="popup-header">
                                <img src={Icon} alt="DeepKeys Logo" className="popup-logo" />
                                <h2>DeepKeys</h2>
                            </div>
                            <h3>Subscribe for Updates</h3>
                            <form onSubmit={handleSubscribe}>
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <button type="submit">Subscribe</button>
                            </form>
                            {subscriptionStatus && <p>{subscriptionStatus}</p>}
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}

export default LandingPage;
