import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UilArrowLeft } from "@iconscout/react-unicons";

const EULA = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const script = document.createElement('script');
        script.id = 'termly-jssdk';
        script.src = 'https://app.termly.io/embed-policy.min.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div>
            <button 
                onClick={handleBack}
                style={{
                    position: 'absolute',
                    top: '1rem',
                    left: '1rem',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer'
                }}
                aria-label="Go back"
            >
                <UilArrowLeft size="24" color="#000000" />
            </button>
            <div name="termly-embed" data-id="6352b8d6-09e8-4290-9bb2-1719b8fbb698"></div>
        </div>
    );
};

export default EULA;
