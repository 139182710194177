export const metricColorMapping = () => {
    return {
        "happiness": "#ffdd57",  // Yellow for happiness, bright and cheerful
        "motivation": "#ff6f61",  // Reddish for motivation, energetic
        "stress": "#db0000",      // Blood Red for stress
        "optimism": "#ffc107",    // Gold for optimism, positive and shiny
        "self-awareness": "#007bff", // Blue for awareness, calm and deep
        "self-doubt": "#adb5bd",  // Light grey for doubt, muted
        "resilience": "#28a745",  // Green for resilience, growth and renewal
        "inspiration": "#9c27b0", // Purple for inspiration, creative and insightful
        "burnout": "#AA4203",     // Fire orange
        "flow-state": "#00acc1",  // Cyan for flow state, fluid and engaging
        "satisfaction": "#f4511e",// Orange for satisfaction, warm and fulfilling
        "efficiency": "#33b679",  // Leaf green for efficiency, productive
        "time management": "#f09300", // Amber for time management, urgent
        "discipline": "#fdd835",
        "empathy": "#00bcd4",     // Light blue for empathy, soft and understanding
        "joy": "#5e35b1",
        "personal development": "#005A9B",
        "growth": "#c0ca33",      // Lime for growth, vibrant and alive
        "focus": "#e53935",       // Strong red for focus, demanding attention
        "gratitude": "#fb8c00",   // Deep orange for gratitude, warm and thankful
        "insecurity": "#8e24aa",  // Magenta for insecurity
        "contentment": "#1e88e5", // Lighter blue for contentment, peaceful
        "determination": "#d81b60",// Hot pink for determination, intense and passionate
        "organization": "#039be5",// Sky blue for organization, clear and structured
        "confidence": "#c62828",  // Dark red for confidence, bold and assertive
        "hopefulness": "#43a047", // Forest green for hopefulness, optimistic and steady
        "achievement": "#2e7d32", // Dark green for achievement, successful
        "learning": "#7b1fa2",    // Purple for learning, thoughtful and introspective
        "goal-setting": "#00897b",// Teal for goal setting, balanced and steady
        "fulfillment": "#e65100", // Orange for fulfillment, deep and rich
        "adaptability": "#3949ab",// Indigo for adaptability, flexible and enduring
        "empowerment": "#fbc02d",  // Yellow for empowerment, bright and bold
        "anxiety": "#692544", // Magenta for anxiety
        "love": "#63469d", // Red of love
        // Categories
        "mental health": "#478dcd",
        "productivity": "#47cd87",
        "personal growth": "#8747cd",
        "overall": "#40bf00",
        "wellbeing": "#40bf00",
        "positive": "#39ff14",
        "negative": "#ff3131"
    };
};
