import React, { useState, useEffect, useRef } from "react";
import "../Styles/SideBar.css";
import Icon from "../Resources/icon.png";
import { UilSignout, UilBars, UilSearch, UilSetting, UilHome, UilCloudDownload, UilBell, UilTrophy, UilChart } from "@iconscout/react-unicons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import SearchBar from './SearchBar';
import { useNavigate } from 'react-router-dom';

import { auth, db } from '../Firebase/firebaseConfig';
import { getFollowersByStatus, respondToFollowRequest } from '../Firebase/followUtils';
import { getUserBasicInfoFromUid } from '../Firebase/readUserDetails';
import handleDownload from '../Firebase/handleDownload';

import { toast } from 'react-toastify';

import { motion, AnimatePresence } from "framer-motion";

const Sidebar = ({ activeItem = "Dashboard" }) => {
  const navigate = useNavigate();
  const [pendingRequests, setPendingRequests] = useState([]);
  const [selected, setSelected] = useState(activeItem);
  const [expanded, setExpanded] = useState(true);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [isIconColumnOpen, setIsIconColumnOpen] = useState(false);
  const [isAreaTransitioning, setIsAreaTransitioning] = useState(false);
  const [isViewTransitioning, setIsViewTransitioning] = useState(false);

  const searchRef = useRef(null);
  const notificationsRef = useRef(null);
  const iconColumnRef = useRef(null);

  useEffect(() => {
      const handleClickOutside = (event) => {
          if (searchRef.current && !searchRef.current.contains(event.target) &&
              !iconColumnRef.current.contains(event.target)) {
              setShowSearchBar(false);
              setIsIconColumnOpen(false);
          }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, []);

  useEffect(() => {
      const handleClickOutside = (event) => {
          if (notificationsRef.current && !notificationsRef.current.contains(event.target) &&
              !iconColumnRef.current.contains(event.target)) {
              setShowNotifications(false);
              setIsIconColumnOpen(false);
          }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, []);

  useEffect(() => {
      const checkForPendingRequests = async () => {
          try {
            const requests = await getFollowersByStatus(db, auth.currentUser.uid, "requested");
            let requesterData = [];
            for (const request of requests) {
              const rData = await getUserBasicInfoFromUid(request.id);
              if (rData !== null) {
                requesterData.push({
                  id: request.id,
                  status: request.status,
                  firstName: rData.firstName,
                  lastName: rData.lastName,
                  username: rData.username,
                });
              }
            };

            if (requesterData !== null) {
              setPendingRequests(requesterData);
            }
          } catch (error) {
            console.log("error getting requests:", error);
            setPendingRequests([]);
          }
      };

      checkForPendingRequests();
  }, []);

  const navigateToViewUser = (searchUsername) => {
    navigate(`/user-profile/${searchUsername}`);
  }

  const handleAccept = async (followerUserId) => {
      try {
          const response = await respondToFollowRequest(db, auth.currentUser.uid, followerUserId, "accepted");
          if (response) {
              setPendingRequests(prevRequests => prevRequests.filter(request => request.id !== followerUserId));
              toast.success("Request accepted successfully!");
          } else {
              toast.error("Error accepting request. Please try again!");
          }
      } catch (error) {
          console.error("Error accepting follow request:", error);
          toast.error("Error accepting request. Please try again!");
      }
  };

  const handleReject = async (followerUserId) => {
      try {
          const response = await respondToFollowRequest(db, auth.currentUser.uid, followerUserId, "rejected");
          if (response) {
              setPendingRequests(prevRequests => prevRequests.filter(request => request.id !== followerUserId));
              toast.success("Request rejected successfully!");
          } else {
              toast.error("Error rejecting request. Please try again!");
          }
      } catch (error) {
          console.error("Error rejecting follow request:", error);
          toast.error("Error rejecting request. Please try again!");
      }
  };

  const handleSelectItem = (heading) => {
    if (heading !== selected) {
      setSelected(heading);
      if (heading === "Search" || heading === "Notifications") {
        if (isViewTransitioning) return;
        setIsViewTransitioning(true);
        setIsIconColumnOpen(false);
        setTimeout(() => {
          setIsIconColumnOpen(true);
          if (isAreaTransitioning) return;
          setIsAreaTransitioning(true);
          if (showSearchBar || showNotifications) {
            setShowSearchBar(false);
            setShowNotifications(false);
            setTimeout(() => {
              setShowSearchBar(heading === "Search");
              setShowNotifications(heading === "Notifications");
              setIsAreaTransitioning(false);
            }, 300);
          } else {
            setShowSearchBar(heading === "Search");
            setShowNotifications(heading === "Notifications");
            setIsAreaTransitioning(false);
          }
          setIsViewTransitioning(false);
        }, 300);
      } else {
        if (isViewTransitioning) return;
        setIsViewTransitioning(true);
        setIsIconColumnOpen(false);
        setShowSearchBar(false);
        setShowNotifications(false);
        setTimeout(() => {
          setIsViewTransitioning(false);
        }, 300);
      }
    } else {
      if (isViewTransitioning) return;
      setIsViewTransitioning(true);
      setIsIconColumnOpen(false);
      setShowSearchBar(false);
      setShowNotifications(false);
      setSelected(null);
      setTimeout(() => {
        setIsViewTransitioning(false);
      }, 300);
    }
  };

  const handleLogout = async () => {
      try {
          await auth.signOut();
          navigate('/login');
      } catch (error) {
          console.error('Logout Error:', error);
      }
  };

    const handleDashboard = async () => {
      try {
          navigate('/dashboard');
      } catch (error) {
          console.error('Dashboard Navigate Error:', error);
      }
  };

  const handleSettings = async () => {
      try {
          navigate('/settings');
      } catch (error) {
          console.error('Settings Error:', error);
      }
  };

  const SidebarData = [
    {
      icon: UilHome,
      heading: "Dashboard",
      onClick: ()=>{handleSelectItem("Dashboard"); handleDashboard();},
    },
    {
      icon: UilTrophy,
      heading: "Leaderboard",
      onClick: ()=>{handleSelectItem("Leaderboard"); navigate('/leaderboard');},
    },
    {
      icon: UilChart,
      heading: "Insights",
      onClick: ()=>{handleSelectItem("Insights"); navigate('/insights');},
    },
    {
      icon: UilSearch,
      heading: "Search",
      onClick: ()=>{handleSelectItem("Search");}
    },
    {
      icon: UilBell,
      heading: "Notifications",
      onClick: ()=>{handleSelectItem("Notifications");}
    },
    {
      icon: UilCloudDownload,
      heading: "Download",
      onClick: ()=> {setShowSearchBar(false); handleDownload();}
    },
    {
      icon: UilSetting,
      heading: "Settings",
      onClick: ()=> {handleSelectItem("Settings"); handleSettings();}
    },
    {
      icon: UilSignout,
      heading: "Logout",
      onClick: ()=> {handleSelectItem("Logout"); handleLogout();}
    }
  ];

  const renderMenuItems = () => {
    return SidebarData.map((item, index) => {
      const isSelected = selected === item.heading;
      return (
        <React.Fragment key={index}>
          <div
            className={isSelected ? "menuItem active" : "menuItem"}
            onClick={() => {handleSelectItem(item.heading); item.onClick();}}
          >
            { (item.heading === "Notifications" && pendingRequests.length > 0) ?
                  <div className="notification-count-container">
                  <item.icon className="menu-item-icon" />
                  <span className="notifications-count">{pendingRequests.length}</span>
                  </div>
                 : <item.icon className="menu-item-icon"/>
            }
            <span>{item.heading}</span>
          </div>
        </React.Fragment>
      );
    });
  };

  const renderNotifications = () => {
    return pendingRequests.length === 0 ? (
          <div className="notifications-dropdown-empty-text">No new notifications!</div>
      ) : (
          pendingRequests.map((request) => (
              <div key={request.id} className="notifications-dropdown-item" onClick={() => navigateToViewUser(request.username)}>
                <button className="notifications-dropdown-identity">
                  <span className="notification-dropdown-text">@{request.username} wants to follow you!</span>
                </button>
                <div className="notifications-button-container">
                  <button className="notifications-accept-reject-icons" onClick={(e)=>{e.stopPropagation(); handleAccept(request.id)}}>
                      <FontAwesomeIcon icon={faCheck} />
                  </button>
                  <button className="notifications-accept-reject-icons" onClick={(e)=>{e.stopPropagation(); handleReject(request.id)}}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              </div>
          ))
      )
  }

  const renderIconColumn = () => {
    return (
      <motion.div 
        className="icon-column" 
        ref={iconColumnRef}
        initial={{ width: 0 }}
        animate={{ width: "auto" }}
        exit={{ width: 0 }}
        transition={{ duration: 0.3 }}
      >
        <div className="icon-wrapper">
          <img 
            src={!!window.electronAPI ? Icon : '/icon.png'} 
            alt="icon" 
            className="menu-item-icon" 
            onClick={() => {navigate('/dashboard'); setShowSearchBar(false); setShowNotifications(false); setIsIconColumnOpen(false);}}
          />
        </div>
        {SidebarData.map((item, index) => (
          <div
            key={index}
            className={`icon-wrapper ${selected === item.heading ? 'selected' : ''}`}
            onClick={item.onClick}
            style={selected === item.heading ? { border: '1px solid white', borderRadius: '0.5rem' } : {}}
          >
            <item.icon className="menu-item-icon" />
          </div>
        ))}
      </motion.div>
    );
  };

  const renderView = () => {
    return (
      <AnimatePresence mode="wait">
        {isIconColumnOpen ? (
          <motion.div
            key="icon-column-view"
            className="sidebar-content"
            initial={{ width: 0 }}
            animate={{ width: "100%" }}
            exit={{ width: 0 }}
            transition={{ duration: 0.3 }}
          >
            <AnimatePresence>
              {renderIconColumn()}
            </AnimatePresence>
            <div className="sidebar-area">
              <AnimatePresence>
                {showSearchBar && (
                  <motion.div
                    ref={searchRef}
                    className='search-area'
                    initial={{ width: 0, opacity: 0 }}
                    animate={{ width: "100%", opacity: 1 }}
                    exit={{ width: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <SearchBar />
                  </motion.div>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {showNotifications && (
                  <motion.div
                    ref={notificationsRef}
                    className="notifications-area"
                    initial={{ width: 0, opacity: 0 }}
                    animate={{ width: "100%", opacity: 1 }}
                    exit={{ width: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <span className="notifications-header">Notifications</span>
                    <hr className='notifications-history-sep'/>
                    {renderNotifications()}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </motion.div>
        ) : (
          <motion.div
            key="normal-sidebar-view"
            initial={{ width: "100%" }}
            animate={{ width: "100%" }}
            exit={{ width: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="icon" onClick={() => navigate('/dashboard')}>
                <img src={!!window.electronAPI ? Icon : '/icon.png'} alt="icon"/>
              <span>
                Deep<span>Keys</span>
              </span>
            </div>
            <div className="menu">
              {renderMenuItems()}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  }

  return (
    <>
      <div className="sidebar">
        {renderView()}
      </div>
    </>
  );
};

export default Sidebar;
