import { db, downloadsRef } from './firebaseConfig';
import { collection, query, where, orderBy, limit, getDocs, addDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';

const handleDownload = async () => {
  try {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      toast.error("Only desktop app is supported. Mobile app coming soon!");
      return;
    }

    let osType = '';
    if (window.navigator.userAgent.indexOf("Windows") !== -1) {
      osType = 'windows';
    } else if (window.navigator.userAgent.indexOf("Mac OS") !== -1) {
      osType = 'mac';
    } else if (window.navigator.userAgent.indexOf("Linux") !== -1) {
      osType = 'linux';
    } else {
      toast.error("Error! Could not determine your operating system!");
      return;
    }

    const releasesRef = collection(db, 'releases');
    const q = query(
      releasesRef,
      where("os", "==", osType),
      orderBy("major", "desc"),
      orderBy("minor", "desc"),
      orderBy("patch", "desc"),
      limit(1)
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const releaseDoc = querySnapshot.docs[0]; // The first document is the latest due to the sorting
      const downloadUrl = releaseDoc.data().url;

      // Create a temporary anchor element and trigger a download
      const anchor = document.createElement('a');
      anchor.href = downloadUrl;
      anchor.download = ''; // This attribute can be omitted, but it can also be set to suggest a default filename
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      // Store the download information in the downloads collection
      await addDoc(downloadsRef, {
        os: osType,
        timestamp: new Date(),
        userAgent: navigator.userAgent,
        language: navigator.language,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        referrer: document.referrer || 'Direct',
        platform: navigator.platform
      });

      toast.success("Downloading! Please check your downloads folder.");
    } else {
      toast.error("No release found for your operating system.");
    }
  } catch (error) {
    toast.error("Error downloading latest release: " + error.message);
  }
};

export default handleDownload;
