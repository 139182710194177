import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../Firebase/firebaseConfig';
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from '../Firebase/firebaseConfig';
import { UilUser } from '@iconscout/react-unicons';
import { getUserBasicInfoFromUid } from '../Firebase/readUserDetails';
import { getFollowersAndFollowing } from '../Firebase/followUtils';
import '../Styles/Dashboard.css';
import ChartsDash from '../Components/ChartsDash';
import SideBar from '../Components/SideBar';
import FollowPopup from '../Components/FollowPopup';

import {fetchEntriesInRange} from '../Firebase/fetchEntriesInRange';

function Dashboard() {
    const [numDays, setNumDays] = useState(30);
    const [userData, setUserData] = useState([]);
    const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [userDetails, setUserDetails] = useState(null);
    const [profilePicUrl, setProfilePicUrl] = useState(null);
    const [followData, setFollowData] = useState({ followers: [], following: [], lastVisible: { followers: null, following: null } });
    const [showFollowPopup, setShowFollowPopup] = useState(false);
    const [popupType, setPopupType] = useState('');
    const [lastLoadTime, setLastLoadTime] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfilePic = (userId) => {
            console.log("profilePictures", userId);
            const profilePicRef = ref(storage, `profilePictures/${userId}`);
            getDownloadURL(profilePicRef)
                .then((url) => {
                    setProfilePicUrl(url);
                })
                .catch(() => {
                    console.log('No profile picture found, using default');
                    setProfilePicUrl(null); // Set to null to use the UilUser icon as default
                });
        }

        const fetchData = async () => {
            if (!auth.currentUser) {
                console.log("No user logged in");
                navigate('/login');
                return;
            }

            const currentUser = auth.currentUser;
            const userDets = await getUserBasicInfoFromUid(currentUser.uid);
            setUserDetails(userDets);
            fetchProfilePic(currentUser.uid);

            const followInfo = await getFollowersAndFollowing(currentUser.uid);
            setFollowData(followInfo);

            // Define the date range for fetching data
            const endDate = new Date();
            const startDate = new Date();
            startDate.setDate(endDate.getDate() - numDays);

            const data = await fetchEntriesInRange(startDate, endDate);
            console.log(currentUser.displayName, data);
            data.sort((a, b) => new Date(a.time) - new Date(b.time)); // Make sure 'time' is a Timestamp
            setUserData(data);
            setLastLoadTime(endDate);
        };
        fetchData();
        const handleResize = () => {
            setDimensions({ width: window.innerWidth, height: window.innerHeight });
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [numDays, navigate]);

    // Set up interval to check for new data every minute
    useEffect(() => {
        const intervalId = setInterval(async () => {
            if (!lastLoadTime) return;

            const endDate = new Date();
            const startDate = new Date(lastLoadTime);
            console.log("startDate, endDate", startDate, endDate);

          const newData = await fetchEntriesInRange(startDate, endDate, true, null, false);
            newData.sort((a, b) => new Date(a.time) - new Date(b.time));

            if (newData.length > 0) {
                setUserData(prevData => [...prevData, ...newData]);
                setLastLoadTime(endDate);
            }
        }, 2 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, [lastLoadTime]);

    const handleFollowClick = (type) => {
        setPopupType(type);
        setShowFollowPopup(true);
    };

    const loadMoreFollows = async (type) => {
        const lastId = followData.lastVisible[type];
        const newFollowInfo = await getFollowersAndFollowing(auth.currentUser.uid, 
            type === 'followers' ? lastId : null, 
            type === 'following' ? lastId : null);
        
        setFollowData(prevData => ({
            ...prevData,
            [type]: [...prevData[type], ...newFollowInfo[type]],
            lastVisible: {
                ...prevData.lastVisible,
                [type]: newFollowInfo.lastVisible[type]
            }
        }));
    };

    const handleRemoveFollow = (removedUserId) => {
        setFollowData(prevData => ({
            ...prevData,
            [popupType]: prevData[popupType].filter(user => user.id !== removedUserId)
        }));
    };

  // <div className="dashboard-right-container">
  //   <Leaderboard users={followData.following} />
  //   <Insights userData={userData} />
  // </div>

    return (
      <div className="dashboard-container">
        <div className="dashboard-content">
          <div className="dashboard-split">
            <SideBar/>

            <div className="dashboard-user-profile-container">

              {userDetails !== null && (
                <div className="dashboard-user-background-container">
                  <div className="dashboard-user-profile-container">
                    <header className="dashboard-user-header">
                      {profilePicUrl ? (
                        <img src={profilePicUrl} alt="Profile" className="private-user-profile-pic" />
                      ) : (
                        <UilUser className="private-user-profile-pic" />
                      )}
                      <div className="dashboard-user-info">
                        <span className="dashboard-user-username">{userDetails.username}</span>
                        <span className="dashboard-user-name">{userDetails.firstName} {userDetails.lastName}</span>
                        <div className="dashboard-user-follow-info">
                          <span onClick={() => handleFollowClick('followers')} className="dashboard-user-followers">
                            <span>{followData.followers.length}</span> followers
                          </span>
                          <span onClick={() => handleFollowClick('following')} className="dashboard-user-following">
                            <span>{followData.following.length}</span> following
                          </span>
                        </div>
                      </div>
                    </header>
                    <hr className="dashboard-seperator" />
                  </div>
                </div>
              )}
              <ChartsDash
                userData={userData}
                numDays={numDays}
                dimensions={dimensions}
              />
              
            </div>
            
          </div>
        </div>
        {showFollowPopup && (
          <FollowPopup
            type={popupType}
            users={popupType === 'followers' ? followData.followers : followData.following}
            onClose={() => setShowFollowPopup(false)}
            loadMore={() => loadMoreFollows(popupType)}
            hasMore={!!followData.lastVisible[popupType]}
            onRemoveFollow={handleRemoveFollow}
          />
        )}
      </div>
    )
}

export default Dashboard;