import { usersRef, auth, db } from './firebaseConfig';
import { doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore';

const listenForRunLocalModel = async () => {
  if (auth.currentUser) {
    const userRef = doc(usersRef, auth.currentUser.uid);

    // Set default value if it doesn't exist
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists() || userDoc.data().runLocalOnly === undefined) {
        await setDoc(userRef, { runLocalOnly: true }, { merge: true });
    }

    // Listen for changes
    onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
            const userData = doc.data();
            const runLocalOnly = userData.runLocalOnly;
            if (window.electronAPI && window.electronAPI.sendLocalStatus) {
                window.electronAPI.sendLocalStatus(runLocalOnly);
            }
        }
    });
  }
};

const listenForRunLargeModel = async () => {
  if (auth.currentUser) {
    const userRef = doc(usersRef, auth.currentUser.uid);

    // Set default value if it doesn't exist
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists() || userDoc.data().runLargeModel === undefined) {
      await setDoc(userRef, { runLargeModel: true }, { merge: true });
    }

    // Listen for changes
    onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        const userData = doc.data();
        const runLargeModel = userData.runLargeModel;
        if (window.electronAPI && window.electronAPI.sendLargeModelStatus) {
          window.electronAPI.sendLargeModelStatus(runLargeModel);
        }
      }
    });
  }
};
const listenForUseCuda = async () => {
  if (auth.currentUser) {
    const userRef = doc(usersRef, auth.currentUser.uid);

    // Set default value if it doesn't exist
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists() || userDoc.data().useCuda === undefined) {
      await setDoc(userRef, { useCuda: true }, { merge: true });
    }

    // Listen for changes
    onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        const userData = doc.data();
        const useCuda = userData.useCuda;
        if (window.electronAPI && window.electronAPI.sendUseCudaStatus) {
          window.electronAPI.sendUseCudaStatus(useCuda);
        }
      }
    });
  }
};

export {listenForRunLocalModel, listenForRunLargeModel, listenForUseCuda};
