import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../Styles/ConfirmationModal.css';

const ConfirmationModal = ({ show, onHide, onConfirm, title, body }) => {
    return (
        <Modal show={show} onHide={onHide} centered className="custom-modal">
            <Modal.Header className="modal-header">
                <Modal.Title className="modal-title">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">{body}</Modal.Body>
            <Modal.Footer className="modal-footer">
                <Button variant="secondary" onClick={onHide} className="modal-cancel-btn">Cancel</Button>
                <Button variant="danger" onClick={onConfirm} className="modal-confirm-btn">Confirm</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;
