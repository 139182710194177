import {usersRef, auth, db} from '../Firebase/firebaseConfig';
import { query, where, getDocs, getDoc, doc } from 'firebase/firestore';

const querySearchableUser = async (searchUsername) => {
  return query(usersRef, where('username', '==', searchUsername), where('isSearchable', '==', true));
}

const getQuerySnapFromUsername = async (username) => {
  const usersQuery = await querySearchableUser(username);
  const querySnapshot = await getDocs(usersQuery);
  if (querySnapshot.empty) {
      console.log("Target user does not exist");
      return;
  }

  return querySnapshot;
}

const getUIDFromUsername = async (username) => {
  const querySnapshot = await getQuerySnapFromUsername(username);
  if (querySnapshot.empty) {
      console.log("Target user does not exist");
      return;
  }

  return querySnapshot.docs[0].id;
}

const searchUser = async (searchUsername) => {
  console.log("Searching for", searchUsername);
  const q = await querySearchableUser(searchUsername);

  try {
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
          console.log('No searchable user found with that username');
          return null;
      }
      let result = null;
      querySnapshot.forEach(doc => {
          const userData = doc.data();
          // Extract only the required fields
          result = {
              firstName: userData.firstName,
              lastName: userData.lastName,
              username: userData.username,
              isPrivate: userData.isPrivate
          };
      });
      return result;
  } catch (error) {
      console.error('Error searching user:', error);
      return null;
  }
}

const getUserBasicInfoFromUid = async (uID) => {
  try {
      // Attempt to get the user document
      const docSnap = await getDoc(doc(usersRef, uID));
      if (!docSnap.exists()) {
        console.log("getUserBasicInfoFromUid user doesn't exist");
        return;
      }
      const userData = docSnap.data();
      console.log("getUserBasicInfoFromUid", userData);
      return userData;
  } catch (error) {
    console.log("getUserBasicInfoFromUid", error);
  }
}

const canViewUserEntries = async (targetUsername) => {
  console.log("Checking access for", targetUsername);
  try {
    // Fetch the target user's data by username
    const targetUserRef = await querySearchableUser(targetUsername); // Ensure this function is defined correctly
    const targetUserSnapshot = await getDocs(targetUserRef);

    if (targetUserSnapshot.empty) {
      console.log('No user found with that username');
      return false;
    }

    let canAccess = false;
    for (const udoc of targetUserSnapshot.docs) {
      const targetUserData = udoc.data();
      const targetUserId = udoc.id;

      if (targetUserId === auth.currentUser.uid) {
        canAccess = true;
        break; // Stop the loop if access is granted
      }

      if (!targetUserData.isPrivate) {
        canAccess = true;
        break; // Stop the loop if profile is public
      }

      // Check if the current user is following the target user and is accepted
      const currentUserId = auth.currentUser.uid;
      const followerRef = doc(db, `users/${targetUserId}/followers`, currentUserId);
      const followerDoc = await getDoc(followerRef);

      if (followerDoc.exists() && followerDoc.data().status === 'accepted') {
        canAccess = true;
        break; // Stop the loop if follower status is accepted
      }
    }

    console.log("canAccess", canAccess);
    return canAccess;
  } catch (error) {
    console.error('Error checking user access:', error);
    return false;
  }
}

// Function to fetch the username of the currently logged-in user
const fetchCurrentUsername = async () => {
    const user = auth.currentUser;
    if (!user) {
        console.log("No user is logged in.");
        return null;
    }

    try {
        const userRef = doc(usersRef, user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
            console.log("User document found.");
            const userData = userDoc.data();
            return userData.username;
        } else {
            console.log("User document does not exist.");
            return null;
        }
    } catch (error) {
        console.error("Error fetching user data:", error);
        return null;
    }
};

// Function to check if the username is unique
const isUsernameUnique = async (username) => {
  const usernameQuery = query(usersRef, where("username", "==", username.toLowerCase()));

  try {
    const querySnapshot = await getDocs(usernameQuery);
    return querySnapshot.empty; // Returns true if no documents found, meaning username is unique
  } catch (error) {
    console.error("Error checking username uniqueness:", error);
    return false; // Assume not unique on error
  }
};

export {canViewUserEntries, searchUser, querySearchableUser, getQuerySnapFromUsername, getUIDFromUsername, fetchCurrentUsername, isUsernameUnique, getUserBasicInfoFromUid};
