import React from 'react';
import '../Styles/LandingPage.css';
import '../Styles/FAQ.css';
import { useNavigate } from 'react-router-dom';
import Icon from "../Resources/icon.png";

const FAQ = () => {
    const navigate = useNavigate();
    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div className="faq">

            <header className="landing-header">
                <div className="logo-container" onClick={() => handleNavigation('/')}>
                    <img src={Icon} alt="DeepKeys Logo" className="logo" />
                    <span className="logo-text">DeepKeys</span>
                </div>
                <div className="header-buttons">
                    <button onClick={() => handleNavigation('/')} className="nav-button">Home</button>
                    <button onClick={() => handleNavigation('/login')} className="nav-button">Login</button>
                </div>
            </header>

            <div className="faq-content">
                <h1>Frequently Asked Questions</h1>
                <div className="faq-item">
                    <h3>How does DeepKeys work?</h3>
                    <p>
                        DeepKeys uses AI to watch how you type and move your mouse. It gives you helpful insights about your mental health and productivity, kind of like a heart-rate monitor but for your mind.
                    </p>
                </div>
                <div className="faq-item">
                    <h3>Can I use DeepKeys on mobile devices?</h3>
                    <p>
                        Right now, DeepKeys is only available for desktop computers. We're working on making it available for mobile devices soon. Stay tuned for updates!
                    </p>
                </div>
                <div className="faq-item">
                    <h3>Is my data secure?</h3>
                    <p>
                        Yes, keeping your data safe and private is very important to us. We don't process the raw text data on our servers unless you specifically choose to in the settings. All the analysis happens right on your computer, so your raw data never leaves your device. The results, like your happiness and stress levels, are securely stored in the cloud. We might use these results in anonymous form to create summaries and insights.
                    </p>
                    <p>
                        By default, all user accounts are private, and your data isn't shared with anyone unless you accept a follow request or change your account to be public.
                    </p>
                </div>
                <div className="faq-item">
                    <h3>I downloaded the app, but there's no data showing. What's wrong?</h3>
                    <h4>General Troubleshooting:</h4>
                    <p>
                        If you've installed the app but aren't seeing any data, there could be several reasons:
                        <ul>
                            <li>The app might not have the necessary permissions to monitor your input.</li>
                            <li>Your antivirus software might be blocking the app.</li>
                            <li>The app might not be running correctly in the background.</li>
                        </ul>
                        Try adding the app to the safe list in your antivirus settings. If you're still experiencing issues after trying the OS-specific solutions below, please contact our support team for further assistance.
                    </p>
                    <h4>For Windows Users:</h4>
                    <p>
                        The monitoring process might be blocked by your antivirus software. To check and resolve this, please follow these steps:
                        <ol>
                            <li>Press the Windows key, type "Task Manager", and press Enter.</li>
                            <li>Open the Task Manager.</li>
                            <li>Find and click on "DeepKeys" to view its subprocesses.</li>
                            <li>Check if "kl.exe" is listed under DeepKeys.</li>
                            <li>If "kl.exe" is running, no further action is needed. If it is not visible, the process is likely being blocked by your antivirus software. In this case, please whitelist DeepKeys in your antivirus program.</li>
                        </ol>
                    </p>
                    <p>
                        Checking Windows Security Settings:
                        <ol>
                            <li>Press the Windows key, type "Windows Security," and press Enter.</li>
                            <li>Click on "Virus & threat protection" in the left menu.</li>
                            <li>Under "Virus & threat protection settings," click on "Manage settings."</li>
                            <li>Scroll down and click on "Add or remove exclusions."</li>
                            <li>Click "Add an exclusion," then select "Folder."</li>
                            <li>Choose the folder where DeepKeys is installed.</li>
                        </ol>
                    </p>
                    <h4>For Mac Users:</h4>
                    <p>
                        Input monitoring might not be enabled in your computer's settings. You can find instructions on how to enable input monitoring <a href="https://support.apple.com/en-gb/guide/mac-help/mchl4cedafb6/mac" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold'}}>here</a>. After enabling input monitoring, restart the DeepKeys app.
                    </p>
                    <h4>For Linux Users:</h4>
                    <p>
                        Ensure that you are running the app under a non-root user & have the necessary permissions set for the app to run and monitor input. You might need to run the app with sudo privileges or adjust your system's security settings. Check your distribution's documentation for specific instructions on granting input monitoring permissions to applications.
                    </p>
                </div>
                <div className="faq-item">
                    <h3>How can I get support?</h3>
                    <p>
                        If you have any questions or need help, please email us at <a href="mailto:support@deepkeys.ai">support@deepkeys.ai</a>. We're here to assist you!
                    </p>
                </div>
            </div>

            <footer className="landing-footer">
                <div className="footer-links">
                    <button>© DeepKeys LLC 2023-2024</button>
                    <button onClick={() => handleNavigation('/privacy-policy')}>Privacy Policy</button>
                    <button onClick={() => handleNavigation('/eula')}>EULA</button>
                    <button onClick={() => handleNavigation('/terms-of-use')}>Terms of Use</button>
                    <button onClick={() => handleNavigation('/data-request')}>Data Request</button>
                    <button onClick={() => handleNavigation('/disclaimer')}>Disclaimer</button>
                    <button
                        onClick={() => window.location.href = 'mailto:support@deepkeys.ai'}
                        onMouseEnter={(e) => {
                            const tooltip = document.createElement('div');
                            tooltip.textContent = 'mailto:support@deepkeys.ai';
                            tooltip.style.position = 'fixed';
                            tooltip.style.bottom = '10px';
                            tooltip.style.left = '10px';
                            tooltip.style.color = 'white';
                            tooltip.style.fontSize = '12px';
                            document.body.appendChild(tooltip);
                            e.target.addEventListener('mouseleave', () => tooltip.remove());
                        }}
                    >
                        Contact Support
                    </button>
                </div>

            </footer>

        </div>
    );
};

export default FAQ;
