import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import {MultiMetricDataModel} from '../Utilities/DataModel'
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { saveSummaryToFirebase, fetchSummaryFromFirebase } from '../Firebase/summaryUtils';
import { getUIDFromUsername } from '../Firebase/readUserDetails';
import { auth } from '../Firebase/firebaseConfig';
import '../Styles/Summary.css'


function SummaryComponent({ apiUrl, userData, categories, title='' }) {
  const [summary, setSummary] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [copied, setCopied] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [summaryTimestamp, setSummaryTimestamp] = useState(null);
  let apiCalled = false;

  const fetchSummary = async (forceRefresh = false) => {
    if (!isMounted) return;
    setLoading(true);
    setError('');
    try {
      const dataModel = new MultiMetricDataModel(userData, categories);
      const mostRecentDatapoint = dataModel.getMostRecentDatapoint();

      if (!forceRefresh) {
        const today = new Date().toISOString().split('T')[0];
        const uid = window.location.pathname.startsWith('/user-profile/') 
          ? await getUIDFromUsername(window.location.pathname.split('/').pop())
          : auth.currentUser.uid;
        const existingSummaries = await fetchSummaryFromFirebase(uid, today, title);
        if (existingSummaries.length > 0) {
          const latestSummary = existingSummaries[0];
          const summaryTimestamp = new Date(latestSummary.date);
          const currentTime = new Date();
          const timeDifference = currentTime - summaryTimestamp;
          
          if (timeDifference > 300000 
            && mostRecentDatapoint 
            && new Date(mostRecentDatapoint.date) > summaryTimestamp
            && new Date(mostRecentDatapoint.date) <= currentTime 
            && isMounted && !apiCalled) {
              apiCalled = true;
              const response = await axios.post(apiUrl, dataModelSummary);
              console.log("api response", response);
              setSummary(response.data.results);
              const currentTimestamp = new Date();
              setSummaryTimestamp(currentTimestamp);
              if (window.location.pathname === '/dashboard') {
                saveSummaryToFirebase(currentTimestamp.getTime().toString(), response.data.results, title);
              }
              apiCalled = false;
          } else if (isMounted) {
            setSummary(latestSummary.summary);
            setSummaryTimestamp(summaryTimestamp);
            setLoading(false);
          }
          return;
        }
      }

      const dataModelSummary = dataModel.getSummaryData();
      console.log("dataModel", dataModelSummary);
      if (userData && userData.length > 0) {
        console.log("Generating summary...", apiUrl);
        setSummaryTimestamp(null);
        setSummary("Generating summary...");
        if (isMounted) {
          if (!apiCalled) {
            apiCalled = true;
            const response = await axios.post(apiUrl, dataModelSummary);
            console.log("api response", response);
            setSummary(response.data.results);
            const currentTimestamp = new Date();
            setSummaryTimestamp(currentTimestamp);
            if (window.location.pathname === '/dashboard') {
              saveSummaryToFirebase(currentTimestamp.getTime().toString(), response.data.results, title);
            }
            apiCalled = false;
          }
        }
      }
    } catch (error) {
      if (isMounted) {
        setError('Failed to fetch summary: ' + error.message);
        console.error(error);
      }
    } finally {
      if (isMounted) setLoading(false);
    }
  };

  useEffect(() => {
    setIsMounted(true);
    fetchSummary();
    return () => setIsMounted(false);
  }, [userData]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(summary).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);  // Reset icon after 2 seconds
    }, (err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  const formatDate = (date) => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <div className="summary-container">
      <div className="summary-header">
        {title && <span className="summary-title">{title}</span>}
        {summaryTimestamp && (
          <span className="summary-timestamp">
            {formatDate(summaryTimestamp)} {summaryTimestamp.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}
          </span>
        )}
      </div>
      {summary ? (
        <>
          <ReactMarkdown children={summary} remarkPlugins={[remarkGfm]} />
          <div className="summary-action-buttons">
            <button onClick={() => fetchSummary(true)} disabled={loading} className="summary-button">
              {loading ? <FontAwesomeIcon icon={faSyncAlt} spin /> : <FontAwesomeIcon icon={faSyncAlt} />}
            </button>
            <button onClick={copyToClipboard} className="summary-button">
              <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
            </button>
          </div>
          <p className="summary-advice">AI can make mistakes. Check important info.</p>
        </>
      ) : (
        <p>No summary available.</p>
      )}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

export default SummaryComponent;
