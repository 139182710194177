import React from "react";
import "../Styles/ChartsDash.css";
import Cards from './Cards';
import SummaryComponent from './SummaryComponent';

import {MENTAL_HEALTH_METRICS, PRODUCTIVITY_METRICS, PERSONAL_GROWTH_METRICS, ALL_METRICS} from '../Utilities/metricGrouping';

const ChartsDash = ({userData, numDays, dimensions}) => {

  const plotlyConfig = {
      displayModeBar: false,
      displaylogo: false,
      responsive: true
  };

  const summaryMetrics = {
    'Overall Wellbeing': ALL_METRICS,
    'Mental Health': MENTAL_HEALTH_METRICS,
    'Productivity': PRODUCTIVITY_METRICS,
    'Personal Growth': PERSONAL_GROWTH_METRICS
  };

  return (
    <div className="charts-container">

      <div className="cards-row">
        <div className="card-chart-grouping overall-wellbeing">
          <h1 className="section-heading">Overall Wellbeing</h1>
          <Cards
            key={userData.length}
            userData={userData}
            categories={["Wellbeing"]}
            plotlyConfig={plotlyConfig}
            numDays={numDays}
            dimensions={dimensions}
          />
        </div>

        <div className="card-chart-grouping wellbeing-indicators">
          <h1 className="section-heading">Wellbeing Indicators</h1>
          <Cards
            key={userData.length}
            userData={userData}
            categories={["Mental Health", "Productivity", "Personal Growth"]}
            plotlyConfig={plotlyConfig}
            numDays={numDays}
            dimensions={dimensions}
          />
        </div>
      </div>

      <div className="charts-dash-summary-container">
      {userData && (
        <SummaryComponent apiUrl={process.env.REACT_APP_DKAI_SUMMARIZE_OVERALL} userData={userData} categories={summaryMetrics} title={"Summary"}/>
      )}
      </div>
    </div>
  );
};

export default ChartsDash;
