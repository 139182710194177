import React, { useState, useEffect } from 'react';
import icon from '../Resources/icon.png';
import '../Styles/Loading.css';

const Loading = (isForFetch=true) => {
  const [loading, setLoading] = useState(true);
  const minLoadingTime = 3000;

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, minLoadingTime);

    return () => clearTimeout(timer);
  }, [minLoadingTime]);

  if (!loading) {
    return null;
  }

  if (isForFetch) {
    return (
      <div className="loading-container"></div>
    );
  }

  return (
    <div className="loading-container">
      <img src={icon} alt="Loading..." className="loading-icon" />
      <div className="spinner"></div>
    </div>
  );
};

export default Loading;
