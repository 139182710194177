const firebaseErrorCodes = {
  'auth/email-already-in-use': 'The provided email is already exists. Please try logging in.',
  'auth/invalid-credential': 'The email address or password is incorrect.',
  'auth/user-not-found': 'There is no user record corresponding to the provided email.',
  'auth/weak-password': 'The password is too weak.',
  'auth/too-many-requests': 'We have blocked all requests from this device due to unusual activity. Try again later.',
  'auth/operation-not-allowed': 'This operation is not allowed. You may have disabled it in the Firebase console.',
  'auth/internal-error': 'An internal error has occurred. Please try again later.'
};

export function handleFirebaseError(error) {
  return firebaseErrorCodes[error.code] || `An unknown error occurred: ${error.code}`;
}
