import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import './Styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './Context/AuthContext';

// For accessing via a method, ensure the method is correctly defined and used:
const nodeEnv = (window.electronAPI && window.electronAPI.nodeEnv) || undefined;

// electron desktop build requires HashRouter
// https://stackoverflow.com/questions/36505404/how-to-use-react-router-with-electron
const Router = nodeEnv === "production" ? HashRouter : BrowserRouter;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <AuthProvider>
      <Router>
        <App />
      </Router>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
