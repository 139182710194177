import React, { useState, useEffect } from 'react';
import './Styles/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import AuthForm from './Pages/AuthForm';
import Dashboard from './Pages/Dashboard';
import NotFound from './Pages/NotFound';
import IOISignup from './Pages/IOISignup';
import SettingsForm from './Pages/SettingsForm';
import UserProfile from './Pages/UserProfile';
import UserProfileViewer from './Pages/UserProfileViewer';
import Loading from './Pages/Loading';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import EULA from './Pages/EULA';
import TermsOfUse from './Pages/TermsOfUse';
import DataRequestForm from './Pages/DataRequestForm';
import Disclaimer from './Pages/Disclaimer';
import ForgotPassword from './Pages/ForgotPassword';
import LandingPage from './Pages/LandingPage';
import Leaderboard from './Pages/Leaderboard';
import Insights from './Pages/Insights';
import Why from './Pages/Why';
import FAQ from './Pages/FAQ';
import { auth } from './Firebase/firebaseConfig';
import {listenForRunLocalModel, listenForRunLargeModel, listenForUseCuda} from './Firebase/listenForUserSettings';
import { uploadMetricData } from './Firebase/uploadMetricData';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      // The listener will trigger immediately and provide the current auth state
      const unsubscribe = auth.onAuthStateChanged(user => {
          setCurrentUser(user);
          setLoading(false); // Set loading to false once we receive the auth state
          if (window.electronAPI && window.electronAPI.sendMetricData) {
            window.electronAPI.sendAuthStatus(!!user);
          }
          if (user) {
            listenForRunLocalModel(); // Start listening for runLocalModel changes
            listenForRunLargeModel(); // Start listening for runLargeModel changes
            listenForUseCuda(); // Start listening for useCuda changes
          }
      });

      // Cleanup the listener when the component unmounts
      return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (window.electronAPI && window.electronAPI.sendMetricData) {
      // Listening for data from main process
      window.electronAPI.onMetricData('metric-data-channel', (data) => {
          console.log('Received metric data:', data);
          uploadMetricData(data);
      });
    }

    if (window.electronAPI && window.electronAPI.sendDataCollectionStatus) {
      // Listening for data collection status
      window.electronAPI.onMetricData('data-collection-status', (data) => {
        console.log('Received data collection status:', data);
        if (data.running) {
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      });
    }
  }, []);

  if (loading) {
      return <Loading/>;
  }

  // <Route exact path="/why" element={<Why />} />

  return (
    <div>
      <div className="App">
        <Routes>
          <Route exact path="/dashboard" element={currentUser? <Dashboard />: <Navigate to="/login" />} />
          <Route exact path="/login" element={currentUser ? <Navigate to="/dashboard" /> : <AuthForm />} />
          <Route exact path="/settings" element={currentUser ? <SettingsForm /> : <Navigate to="/login" />} />
          <Route exact path="/subscribe" element={<IOISignup />} />
          <Route path="/user-profile/:username" element={currentUser? <UserProfileViewer />:  <Navigate to="/login" />} />
          <Route path="/leaderboard" element={currentUser? <Leaderboard />:  <Navigate to="/login" />} />
          <Route path="/insights" element={currentUser? <Insights />:  <Navigate to="/login" />} />
          <Route exact path="/" element={
            currentUser ? <Navigate to="/dashboard" /> : 
            (window.electronAPI ? <AuthForm /> : <LandingPage />)
          } />
          <Route exact path="/faq" element={<FAQ />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/eula" element={<EULA />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/data-request" element={<DataRequestForm />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <ToastContainer autoClose={2500} />
      </div>
    </div>
  );
}

export default App;
