import { db } from './firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';

const handlePageHit = async (entryDir = 'landingPageHitUnique') => {
  try {
    const landingPageHitRef = collection(db, entryDir);

    await addDoc(landingPageHitRef, {
      timestamp: new Date(),
      userAgent: navigator.userAgent,
      language: navigator.language,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      referrer: document.referrer || 'Direct',
      platform: navigator.platform
    });

    console.log("Landing page hit recorded successfully.");
  } catch (error) {
    console.error("Error recording landing page hit:", error.message);
  }
};

export default handlePageHit;
