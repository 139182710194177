import { usersRef, auth } from './firebaseConfig';
import { doc, setDoc, query, collection, where, orderBy, getDocs } from 'firebase/firestore';

const fetchSummaryFromFirebase = async (uid, summaryDate, title) => {
  const summariesRef = collection(usersRef, uid, "summaries");
  const q = query(
    summariesRef,
    where("title", "==", title),
    where("date", ">=", new Date(summaryDate).toISOString()),
    where("date", "<", new Date(new Date(summaryDate).getTime() + 24*60*60*1000).toISOString()),
    orderBy("date", "desc")
  );

  const querySnapshot = await getDocs(q);
  const summaries = [];

  querySnapshot.forEach((doc) => {
    summaries.push(doc.data());
  });
  console.log("Summaries fetched from Firebase", summaries);
  return summaries;
};

const saveSummaryToFirebase = async (summaryID, summary, title) => {
  if (!auth.currentUser) {
    console.error("User must be authenticated to save a summary");
    return;
  }

  const docRef = doc(usersRef, auth.currentUser.uid, "summaries", summaryID);
  const utcDate = new Date().toISOString();
  await setDoc(docRef, { summary: summary, date: utcDate, title: title });
  console.log("Summary saved to Firebase", summaryID, title, utcDate);
};

export { fetchSummaryFromFirebase, saveSummaryToFirebase };