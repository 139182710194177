import React, { useState, useEffect } from 'react';
import { auth, db } from '../Firebase/firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { handleFirebaseError } from '../Firebase/errorCodes';
import '../Styles/AuthForm.css';
import logo from '../Resources/icon.png';
import { useNavigate, Link } from 'react-router-dom';
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { isUsernameUnique } from '../Firebase/readUserDetails';
import { sendEmailVerification } from "firebase/auth";
import { toast } from 'react-toastify';

function AuthForm() {
    const [isLogin, setIsLogin] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [agreeToTerms, setAgreeToTerms] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (!isLogin) {
            if (username.length < 8 || username.length > 32) {
                setError("Username must be between 8 and 32 characters.");
                return;
            }

            // Regex to check for allowed characters (alphanumeric and underscores)
            const usernameRegex = /^[a-zA-Z0-9_.]+$/;

            // Validate username characters
            if (username && !usernameRegex.test(username)) {
                setError('Username can only contain alphanumeric characters, underscores and periods.');
                return;
            }

            const isUnique = await isUsernameUnique(username);
            if (!isUnique) {
                setError("Username already taken.");
                return;
            }

            if (!agreeToTerms) {
                setError("You must agree to the Terms of Use to create an account.");
                return;
            }
        }

        try {
            if (isLogin) {
                await signInWithEmailAndPassword(auth, email, password);
                console.log('User logged in');
                // Handle navigation or state update on login
            } else {
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                console.log('User account created');

                const user = userCredential.user;
                // Create a new document in Firestore with default settings
                const userDocRef = doc(db, "users", user.uid);
                await setDoc(userDocRef, {
                    username: username.toLowerCase(),
                    isPrivate: true,
                    isSearchable: true,
                    createdAt: serverTimestamp()
                });
                console.log('User data document created with default values');

                // Send email verification
                await sendEmailVerification(user);
                console.log('Verification email sent'); 
                toast.success('Verification email sent');
            }
            setEmail('');
            setPassword('');
            navigate('/dashboard');
        } catch (error) {
            console.log(error);
            setError(handleFirebaseError(error));
        }
    };

    const handleForgotPassword = () => {
        navigate('/forgot-password');
    };

    return (
        <div className="auth-background">
            <div className="auth-container">
                <img src={logo} alt="Brand Logo" className="brand-logo" />
                <h2 className="auth-title">{isLogin ? 'Login' : 'Create Account'}</h2>
                <form onSubmit={handleSubmit} className="auth-form">
                    {!isLogin && (
                        <input
                            type="text"
                            className="auth-input"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Username (8-32 characters)"
                            required
                        />
                    )}
                    <input
                        type="email"
                        className="auth-input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        required
                    />
                    <input
                        type="password"
                        className="auth-input"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        required
                    />
                    {!isLogin && (
                        <div className="terms-checkbox">
                            <input
                                type="checkbox"
                                id="agreeToTerms"
                                checked={agreeToTerms}
                                onChange={(e) => setAgreeToTerms(e.target.checked)}
                                required
                            />
                            <label htmlFor="agreeToTerms">
                                I agree to the <Link to="/terms-of-use" target="_blank">Terms of Use</Link>
                            </label>
                        </div>
                    )}
                    <button type="submit" className="auth-button">{isLogin ? 'Login' : 'Create Account'}</button>
                </form>
                {error && <p className="auth-error">{error}</p>}
                {isLogin && (
                    <button onClick={handleForgotPassword} className="forgot-password-link">
                        Forgot your password?
                    </button>
                )}
                <button onClick={() => setIsLogin(!isLogin)} className="auth-toggle">
                    {isLogin ? 'Need to create an account?' : 'Already have an account?'}
                </button>
            </div>
        </div>
    );
}

export default AuthForm;
