import {ALL_METRICS, INVERTED_METRICS} from './metricGrouping'

class SingleMetricDataModel {
    constructor(userData, categoryMetrics=ALL_METRICS, invert=true) {
        this.userData = userData;
        this.categoryMetrics = categoryMetrics;
        this.invert = invert;
        this.dailyData = this.calculateDailyData(categoryMetrics);
        this.dates = this.getDates();
        this.dailyPoints = this.countPointsPerDay();
        this.dailyMeans = this.calculateDailyMeans();
        this.dailySD = this.calculateDailyStandardDeviation();
        this.todayScore = this.calculateTodaysScore();
        this.todaySD = this.calculateTodaysStandardDeviation();
        this.monthlyBaseline = this.calculateMonthlyBaseline();
        this.todayScorePercentile = this.calculatePercentileRanking();
        this.pointsToday = this.countPointsToday();
        this.todayDate = new Date();
    }

    calculateDailyData(categoryMetrics) {
        const dailyScores = {};
        const dailyCounts = {};

        this.userData.forEach(dataPoint => {
            const date = new Date(dataPoint.time).toLocaleDateString();
            if (!dailyScores[date]) {
                dailyScores[date] = {scores: [], count: 0};
            }
            dailyScores[date].count++;

            Object.keys(dataPoint.goals).forEach(metric => {
                if (this.categoryMetrics.includes(metric)) {
                    const score = INVERTED_METRICS.includes(metric) && this.invert ? 1 - dataPoint.goals[metric] : dataPoint.goals[metric];
                    if (!isNaN(score)) {
                        dailyScores[date].scores.push(score*100);
                    }
                }
            });
        });

        return Object.keys(dailyScores).map(date => {
            const scores = dailyScores[date].scores;
            const totalScore = scores.reduce((acc, curr) => acc + curr, 0);
            const averageScore = scores.length > 0 ? totalScore / scores.length : 0;
            return {
                date: date,
                average: averageScore,
                count: dailyScores[date].count,
                scores: scores
            };
        });
    }

    countPointsToday() {
        const today = new Date();
        const todayString = today.toISOString().split('T')[0]; // Gets the date part in YYYY-MM-DD format

        return this.userData.reduce((count, dataPoint) => {
            const dataPointDate = new Date(dataPoint.time).toISOString().split('T')[0];
            if (dataPointDate === todayString) {
                count += 1;
            }
            return count;
        }, 0);
    }

    countPointsPerDay() {
        return this.dailyData.map(day => day.count);
    }

    getDates() {
        return this.dailyData.map(day => day.date);
    }

    calculateDailyMeans() {
        return this.dailyData.map(day => day.average.toFixed(2));
    }

    calculateDailyStandardDeviation() {
        const dailyAverages = this.dailyData.map(day => day.average); // Extract the daily averages
        const mean = dailyAverages.reduce((acc, curr) => acc + curr, 0) / dailyAverages.length; // Calculate the mean of daily averages

        const variance = dailyAverages.reduce((acc, curr) => acc + Math.pow(curr - mean, 2), 0) / dailyAverages.length; // Calculate the variance
        const standardDeviation = Math.sqrt(variance); // Calculate the standard deviation from the variance

        return standardDeviation.toFixed(2);
    }

    calculateTodaysScore() {
        const today = new Date().toLocaleDateString();
        const todayData = this.dailyData.find(day => day.date === today);
        return todayData ? todayData.average.toFixed(2) : 0;
    }

    calculateTodaysMaxScore() {
      const today = new Date().toLocaleDateString();
      const todayData = this.dailyData.find(day => day.date === today);
      if (!todayData || todayData.scores.length === 0) {
          return 0; // Return 0 or suitable value when no data available
      }
      const maxScore = Math.max(...todayData.scores);
      return maxScore.toFixed(2);
    }

    calculateTodaysMinScore() {
      const today = new Date().toLocaleDateString();
      const todayData = this.dailyData.find(day => day.date === today);
      if (!todayData || todayData.scores.length === 0) {
          return 0; // Return 0 or suitable value when no data available
      }
      const minScore = Math.min(...todayData.scores);
      return minScore.toFixed(2);
    }

    calculateTodaysStandardDeviation() {
        const today = new Date().toLocaleDateString();
        const todayData = this.dailyData.find(day => day.date === today);

        if (!todayData || todayData.scores.length < 2) {
            return 0; // Standard deviation is not defined for one point or no points
        }

        const mean = todayData.scores.reduce((acc, score) => acc + score, 0) / todayData.scores.length;
        const variance = todayData.scores.reduce((acc, score) => acc + Math.pow(score - mean, 2), 0) / (todayData.scores.length - 1);
        return Number(Math.sqrt(variance)).toFixed(2);
    }

    getMostRecentDatapoint() {
        if (this.userData.length === 0) {
            return null; // No datapoints available
        }

        // Sort all datapoints by time in descending order and return the first one
        return [...this.userData].sort((a, b) => new Date(b.time) - new Date(a.time))[0];
    }

    calculateMonthlyBaseline() {
        const last30Days = this.dailyData.slice(-30); // Assuming dailyData is sorted by date

        if (last30Days.length === 0) {
            return 0; // No data available
        }

        let totalScore = 0;
        let totalPoints = 0;

        last30Days.forEach(day => {
            totalScore += day.average * day.count; // Weight the day's average by the number of points
            totalPoints += day.count; // Total number of points over the last 30 days
        });

        // Calculate weighted average
        const weightedAverage = totalPoints > 0 ? totalScore / totalPoints : 0;
        return weightedAverage.toFixed(2);
    }

    calculatePercentileRanking() {
        const todayScore = this.calculateTodaysScore();
        const scores = this.dailyData.map(day => day.average);

        if (scores.length === 0) {
            // Handle the case where there are no scores
            return 'No data available';
        }

        scores.sort((a, b) => a - b);
        const rank = scores.findIndex(score => score > todayScore);

        if (rank === -1) {
            // If today's score is greater than any in the array, it should be 100th percentile.
            return 100;
        } else {
            // Calculate percentile ranking
            const percentile = (rank / scores.length) * 100;
            return percentile.toFixed(2);
        }
    }

    getSummaryData() {
        return {
            todays_score: this.todayScore,
            dates: this.dates,
            points_seen_today: this.pointsToday,
            monthly_baseline: this.monthlyBaseline,
            percentile_ranking: this.todayScorePercentile,
            daily_means: this.dailyMeans,
            point_per_day: this.dailyPoints,
            daily_standard_deviation: this.dailySD
        };
    }

    calculateHighestScoringDaysOfWeek() {
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dayScores = {};

        this.dailyData.forEach(day => {
            const date = new Date(day.date);
            const dayOfWeek = daysOfWeek[date.getDay()];
            if (!dayScores[dayOfWeek]) {
                dayScores[dayOfWeek] = [];
            }
            dayScores[dayOfWeek].push(day.average);
        });

        return Object.entries(dayScores)
            .map(([day, scores]) => ({
                day,
                averageScore: scores.reduce((acc, curr) => acc + curr, 0) / scores.length
            }))
            .sort((a, b) => b.averageScore - a.averageScore)
            .map(entry => ({
                day: entry.day,
                score: entry.averageScore.toFixed(2)
            }));
    }

    calculateHighestScoringHours() {
        const hourlyScores = {};

        this.userData.forEach(dataPoint => {
            const hour = new Date(dataPoint.time).getHours();
            if (!hourlyScores[hour]) {
                hourlyScores[hour] = [];
            }

            Object.keys(dataPoint.goals).forEach(metric => {
                if (this.categoryMetrics.includes(metric)) {
                    const score = INVERTED_METRICS.includes(metric) && this.invert ? 1 - dataPoint.goals[metric] : dataPoint.goals[metric];
                    if (!isNaN(score)) {
                        hourlyScores[hour].push(score * 100);
                    }
                }
            });
        });

        return Object.entries(hourlyScores)
            .map(([hour, scores]) => ({
                hour: parseInt(hour),
                averageScore: scores.reduce((acc, curr) => acc + curr, 0) / scores.length
            }))
            .sort((a, b) => b.averageScore - a.averageScore)
            .map(entry => ({
                hour: entry.hour,
                score: entry.averageScore.toFixed(2)
            }));
    }

    calculateImpactOn(otherModel) {
        // Ensure both models have the same number of data points
        const minLength = Math.min(this.dailyData.length, otherModel.dailyData.length);
        const thisData = this.dailyData.slice(-minLength);
        const otherData = otherModel.dailyData.slice(-minLength);

        // Calculate correlation coefficient
        const correlation = this.calculateCorrelation(thisData, otherData);

        // Calculate average impact
        const forwardImpact = this.calculateAverageImpact(thisData, otherData);
        const backwardImpact = this.calculateAverageImpact(otherData, thisData);

        return {
            correlation: correlation.toFixed(2),
            forwardImpact: forwardImpact.toFixed(2),
            backwardImpact: backwardImpact.toFixed(2)
        };
    }

    calculateCorrelation(thisData, otherData) {
        const n = thisData.length;
        let sumX = 0, sumY = 0, sumXY = 0, sumX2 = 0, sumY2 = 0;

        for (let i = 0; i < n; i++) {
            const x = thisData[i].average;
            const y = otherData[i].average;
            sumX += x;
            sumY += y;
            sumXY += x * y;
            sumX2 += x * x;
            sumY2 += y * y;
        }

        const numerator = n * sumXY - sumX * sumY;
        const denominator = Math.sqrt((n * sumX2 - sumX * sumX) * (n * sumY2 - sumY * sumY));

        return numerator / denominator;
    }

    calculateAverageImpact(sourceData, targetData) {
        const impacts = sourceData.map((day, index) => {
            const sourceChange = index > 0 ? day.average - sourceData[index - 1].average : 0;
            const targetChange = index > 0 ? targetData[index].average - targetData[index - 1].average : 0;
            return sourceChange !== 0 ? targetChange / sourceChange : 0;
        });

        return impacts.reduce((sum, impact) => sum + impact, 0) / impacts.length;
    }

    calculateActivityScoreRelationship() {
        return this.dailyData.map(day => ({
            date: day.date,
            numberOfDataPoints: day.count,
            score: day.average
        }));
    }

    calculateBreakImpact() {
        const BREAK_THRESHOLD = 5 * 60 * 1000; // 5 minutes in milliseconds
        let results = [];
        let currentPeriod = null;

        this.userData.sort((a, b) => new Date(a.time) - new Date(b.time));

        for (let i = 0; i < this.userData.length; i++) {
            const currentPoint = this.userData[i];
            const currentTime = new Date(currentPoint.time);

            if (currentPeriod === null) {
                currentPeriod = {
                    start: currentTime,
                    scores: [],
                    dataPoints: []
                };
            }

            currentPeriod.scores.push(this.calculateScore(currentPoint));
            currentPeriod.dataPoints.push(currentPoint);

            if (i < this.userData.length - 1) {
                const nextPoint = this.userData[i + 1];
                const nextTime = new Date(nextPoint.time);
                const timeDiff = nextTime - currentTime;

                if (timeDiff >= BREAK_THRESHOLD) {
                    // Break detected
                    const averageScore = currentPeriod.scores.reduce((a, b) => a + b, 0) / currentPeriod.scores.length;
                    results.push({
                        start: currentPeriod.start,
                        end: currentTime,
                        previousScore: results.length > 0 ? results[results.length - 1].currentScore : null,
                        currentScore: averageScore,
                        breakLength: timeDiff / 60000 // Convert to minutes
                    });

                    currentPeriod = null;
                }
            }
        }

        // Handle the last period
        if (currentPeriod !== null) {
            const averageScore = currentPeriod.scores.reduce((a, b) => a + b, 0) / currentPeriod.scores.length;
            results.push({
                start: currentPeriod.start,
                end: new Date(this.userData[this.userData.length - 1].time),
                previousScore: results.length > 0 ? results[results.length - 1].currentScore : null,
                currentScore: averageScore,
                breakLength: 0
            });
        }

        return results;
    }

    calculateScore(dataPoint) {
        let totalScore = 0;
        let count = 0;

        Object.keys(dataPoint.goals).forEach(metric => {
            if (this.categoryMetrics.includes(metric)) {
                const score = INVERTED_METRICS.includes(metric) && this.invert ? 1 - dataPoint.goals[metric] : dataPoint.goals[metric];
                if (!isNaN(score)) {
                    totalScore += score * 100;
                    count++;
                }
            }
        });

        return count > 0 ? totalScore / count : 0;
    }
}

class MultiMetricDataModel {
  constructor(userData, categories, invert=true) {
      this.userData = userData;
      this.metricSummaries = {};
      this.mostRecentDatapoints = {};
      Object.keys(categories).forEach(category => {
        const singleMetricModel = new SingleMetricDataModel(userData, categories[category], invert);
        this.metricSummaries[category] = singleMetricModel.getSummaryData();
          this.mostRecentDatapoints[category] = singleMetricModel.getMostRecentDatapoint();
      });
  }

  getSummaryData() {
    return {
      metric_summaries: this.metricSummaries
    };
  }

  getMostRecentDatapoint() {
    const validDatapoints = Object.entries(this.mostRecentDatapoints)
      .filter(([, datapoint]) => datapoint !== null && datapoint.timestamp);
    
    if (validDatapoints.length === 0) {
      return null; // Return null if all datapoints are null or have no timestamp
    }

    const sortedDatapoints = validDatapoints
      .sort(([, a], [, b]) => new Date(b.timestamp) - new Date(a.timestamp));
    
    return sortedDatapoints[0][1];
  }

  calculateCategoryImpacts() {
    const impacts = {};
    const categories = Object.keys(this.metricSummaries);

    for (let i = 0; i < categories.length; i++) {
        for (let j = i + 1; j < categories.length; j++) {
            const category1 = categories[i];
            const category2 = categories[j];

            const model1 = new SingleMetricDataModel(this.userData, this.metricSummaries[category1].categoryMetrics);
            const model2 = new SingleMetricDataModel(this.userData, this.metricSummaries[category2].categoryMetrics);

            impacts[`${category1}_on_${category2}`] = model1.calculateImpactOn(model2);
            impacts[`${category2}_on_${category1}`] = model2.calculateImpactOn(model1);
        }
    }

    return impacts;
  }
}

export {SingleMetricDataModel, MultiMetricDataModel};