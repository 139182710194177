import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/FollowPopup.css';
import { UilUser, UilTimes } from '@iconscout/react-unicons';
import { getUserBasicInfoFromUid } from '../Firebase/readUserDetails';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage, auth } from '../Firebase/firebaseConfig';
import { deleteFollowEntry } from '../Firebase/followUtils';
import { db } from '../Firebase/firebaseConfig';

const FollowPopup = ({ type, users, onClose, loadMore, hasMore, onRemoveFollow }) => {
  const navigate = useNavigate();
  const popupRef = useRef(null);
  const listRef = useRef(null);
  const [userDetails, setUserDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const details = await Promise.all(
        users.map(async (user) => {
          const userInfo = await getUserBasicInfoFromUid(user.id);
          const profilePicUrl = await fetchProfilePic(user.id);
          return { 
            ...userInfo, 
            status: user.status,
            username: userInfo.username,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            profilePic: profilePicUrl,
            id: user.id  // Ensure the id is included in userDetails
          };
        })
      );
      setUserDetails(details);
    };

    fetchUserDetails();
  }, [users]);

  const fetchProfilePic = async (userId) => {
    const profilePicRef = ref(storage, `profilePictures/${userId}`);
    try {
      const url = await getDownloadURL(profilePicRef);
      return url;
    } catch (error) {
      console.log('No profile picture found, using default');
      return null;
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current && !isLoading && hasMore) {
        const { scrollTop, scrollHeight, clientHeight } = listRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 5) {
          loadMoreUsers();
        }
      }
    };

    const listElement = listRef.current;
    if (listElement) {
      listElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (listElement) {
        listElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isLoading, hasMore]);

  const loadMoreUsers = async () => {
    if (!isLoading && hasMore) {
      setIsLoading(true);
      await loadMore();
      setIsLoading(false);
    }
  };

  const handleUserClick = (username) => {
    navigate(`/user-profile/${username}`);
  };

  const handleRemoveUser = async (event, userId) => {
    event.stopPropagation();
    try {
      const currentUserUid = auth.currentUser.uid;
      if (currentUserUid && userId) {
        if (type === 'following') {
          await deleteFollowEntry(db, currentUserUid, userId);
        } else if (type === 'followers') {
          await deleteFollowEntry(db, userId, currentUserUid);
        }
        setUserDetails(prevDetails => prevDetails.filter(user => user.id !== userId));
        onRemoveFollow(userId);  // Call the onRemoveFollow function to update Dashboard state
      } else {
        console.error('Invalid currentUserUid or userId', currentUserUid, userId);
      }
    } catch (error) {
      console.error('Error removing user:', error);
    }
  };

  return (
    <div className="follow-popup-backdrop">
      <div ref={popupRef} className="follow-popup-content">
        <button className="follow-popup-close" onClick={onClose}>×</button>
        <h2>{type === 'followers' ? 'Followers' : 'Following'}</h2>
        {userDetails.length === 0 ? (
          <p className="follow-empty-message">
            {type === 'followers' 
              ? 'You have no followers at this time.' 
              : 'You are not following anyone at this time.'}
          </p>
        ) : (
          <ul ref={listRef} className="follow-list">
            {userDetails.map((user, index) => (
              <li key={user.id} className="follow-list-item" onClick={() => handleUserClick(user.username)}>
                <div className="follow-list-item-left">
                  {user.profilePic ? (
                    <img src={user.profilePic} alt={user.username} className="follow-user-pic" />
                  ) : (
                    <UilUser className="follow-user-pic" />
                  )}
                  <div className="follow-user-info">
                    <span className="follow-username">@{user.username}</span>
                    <span className="follow-name">{`${user.firstName} ${user.lastName}`}</span>
                    {user.status !== 'accepted' && <span className="follow-status">({user.status})</span>}
                  </div>
                </div>
                <button className="remove-follow-button" onClick={(e) => handleRemoveUser(e, user.id)}>
                  <UilTimes />
                </button>
              </li>
            ))}
            {isLoading && <li className="loading-message">Loading more...</li>}
          </ul>
        )}
      </div>
    </div>
  );
};

export default FollowPopup;
