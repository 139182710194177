import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../Firebase/firebaseConfig';
import '../Styles/Dashboard.css';
import ChartsDash from '../Components/ChartsDash';
import Insights from './Insights';
import SideBar from '../Components/SideBar';
import '../Styles/UserProfile.css';
import { useAuth } from '../Context/AuthContext';
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from '../Firebase/firebaseConfig';
import { getUIDFromUsername, getQuerySnapFromUsername } from '../Firebase/readUserDetails';
import { checkFollowStatus, createFollowEntry, deleteFollowEntry } from '../Firebase/followUtils';
import { canViewUserEntries } from '../Firebase/readUserDetails';
import { UilUser } from '@iconscout/react-unicons';

import {fetchEntriesInRange} from '../Firebase/fetchEntriesInRange';

function UserProfile() {
    const { username } = useParams();
    const { currentUser } = useAuth();
    const [userDetails, setUserDetails] = useState(null);
    const [profilePicUrl, setProfilePicUrl] = useState(null);
    const [userFollowsBack, setUserFollowsBack] = useState(false);
    const [followStatus, setFollowStatus] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const [numDays, setNumDays] = useState(30);
    const [userData, setUserData] = useState([]);
    const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [lastLoadTime, setLastLoadTime] = useState(null);
    const navigate = useNavigate();
    
    const toggleFollowRequest = async () => {
        const userId = await getUIDFromUsername(username);

        if (followStatus === 'accepted') {
            const result = await deleteFollowEntry(db, currentUser.uid, userId);
            if (result) {
                setFollowStatus(null);
                console.log('Unfollowed successfully!');
            } else {
                console.log('Failed to unfollow');
            }
        } else if (followStatus === 'requested') {
            const result = await deleteFollowEntry(db, currentUser.uid, userId);
            if (result) {
                setFollowStatus(null);
                console.log('Follow request retracted!');
            } else {
                console.log('Failed to retract follow request');
            }
        } else if (followStatus === 'rejected' || followStatus === null) {
            const result = await createFollowEntry(db, currentUser.uid, userId);
            if (result) {
                const hasAccess = await canViewUserEntries(username);
                if (hasAccess) {
                    setFollowStatus('accepted');
                } else {
                    setFollowStatus('requested');
                }
                console.log('Follow request sent!');
            } else {
                console.log('Failed to send follow request');
            }
        }
    };

    const handleBlockUser = async () => {
        // Implement the logic for blocking a user here
        console.log('Block user functionality to be implemented');
        setDropdownOpen(false);
    };

    useEffect(() => {
        const fetchProfilePic = (userId) => {
          console.log("profilePictures", userId);
          const profilePicRef = ref(storage, `profilePictures/${userId}`);
          getDownloadURL(profilePicRef)
              .then((url) => {
                  setProfilePicUrl(url);
              })
              .catch(() => {
                  console.log('No profile picture found, using default');
                  setProfilePicUrl(null); // Set to null to use the default icon
              });
        }

        const fetchData = async () => {
            if (!auth.currentUser) {
                console.log("No user logged in");
                navigate('/login');
                return;
            }

            const querySnapshot = await getQuerySnapFromUsername(username);
            if (!querySnapshot) {
              return;
            }

            const userDets = querySnapshot.docs[0].data();
            const userId = querySnapshot.docs[0].id;
            userDets.uid = userId;
            setUserDetails(userDets);
            fetchProfilePic(userId);

            // Check follow status using UID
            const status = await checkFollowStatus(db, currentUser.uid, userId);
            setFollowStatus(status);

            // Check if the private user follows the current user
            const reverseStatus = await checkFollowStatus(db, userId, currentUser.uid);
            setUserFollowsBack(reverseStatus === 'accepted');

            // Define the date range for fetching data
            const endDate = new Date();
            const startDate = new Date();
            startDate.setDate(endDate.getDate() - numDays);

            const data = await fetchEntriesInRange(startDate, endDate, true, username);
            console.log(username, data);
            data.sort((a, b) => new Date(a.time) - new Date(b.time)); // Make sure 'time' is a Timestamp
            setUserData(data);
            setLastLoadTime(endDate);
        };

        fetchData();

        const handleResize = () => {
            setDimensions({ width: window.innerWidth, height: window.innerHeight });
        };
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [username, numDays, currentUser.uid, navigate]);

    // Set up interval to check for new data every 2 minutes
    useEffect(() => {
        const intervalId = setInterval(async () => {
            if (!lastLoadTime) return;

            const endDate = new Date();
            const startDate = new Date(lastLoadTime);
            console.log("startDate, endDate", startDate, endDate);

            const newData = await fetchEntriesInRange(startDate, endDate, true, username, false);
            newData.sort((a, b) => new Date(a.time) - new Date(b.time));

            if (newData.length > 0) {
                setUserData(prevData => [...prevData, ...newData]);
                setLastLoadTime(endDate);
            }
        }, 2 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, [lastLoadTime, username]);

    return (
      <div className="dashboard-container">
        <div className="dashboard-content">
          <div className="user-profile-split">
            <SideBar/>
            <div className="dashboard-user-profile-container">

            {userDetails !== null && (
              <div className="user-profile-background-container">
                <div className="user-profile-container">
                  <header className="private-user-header">
                    {profilePicUrl ? (
                      <img src={profilePicUrl} alt="Profile" className="private-user-profile-pic" />
                    ) : (
                      <UilUser className="private-user-profile-pic" />
                    )}
                    <div className="private-user-info">
                        <span className="private-user-username">{userDetails.username}</span>
                        <span className="private-user-name">{userDetails.firstName} {userDetails.lastName}</span>
                        {userFollowsBack && (
                          <span className="follows-you-message">
                            {followStatus === 'accepted' ? 'Follows you back' : 'Follows you'}
                          </span>
                        )}
                    </div>
                    {currentUser.uid !== userDetails.uid && (
                      <button onClick={toggleFollowRequest} className="follow-button">
                        {followStatus === 'accepted' ? 'Unfollow' : 
                         followStatus === 'requested' ? 'Requested' : 'Follow'}
                      </button>
                    )}
                  </header>
                  <hr className="seperator"/>
                </div>
              </div>
            )
            }
            <ChartsDash
              userData={userData}
              numDays={numDays}
              dimensions={dimensions}
            />
            </div>
          </div>
        </div>
      </div>
    )
}

export default UserProfile;
