// ["happiness", "motivation", "stress",
                      // "optimism", "self-awareness", "self-doubt",
                      // "resilience", "inspiration", 'burnout',
                      // 'flow-state', 'satisfaction', 'efficiency',
                      // 'time management', 'discipline', 'empathy',
                      // 'love', 'anxiety',
                      // 'growth', 'focus', 'gratitude',
                      // 'insecurity', 'contentment', 'determination',
                      // 'organization', 'confidence', 'hopefulness',
                      // 'achievement', 'learning', 'goal-setting',
                      // 'fulfillment', 'adaptability', 'empowerment']

const MENTAL_HEALTH_METRICS = [
    "happiness", "stress", "motivation", "self-awareness", "self-doubt", "anxiety",
    "optimism", "burnout", "resilience", "contentment",
    "insecurity", "confidence", "love", "hopefulness", "satisfaction", "empathy"
];

const PRODUCTIVITY_METRICS = [
    "motivation", "efficiency", "time management", "discipline",
    "focus", "organization", "goal-setting", "achievement", "flow-state"
];

const PERSONAL_GROWTH_METRICS = [
    "inspiration", "growth", "learning", "empowerment",
    "adaptability", "fulfillment", "gratitude", "determination"
];

const ALL_METRICS = [
    ...new Set([...MENTAL_HEALTH_METRICS, ...PRODUCTIVITY_METRICS, ...PERSONAL_GROWTH_METRICS])
];

const INVERTED_METRICS = ['anxiety', 'burnout', 'self-doubt', 'insecurity', 'self-doubt', 'stress'];

const POSITIVE_METRICS = [...new Set(new Set(ALL_METRICS).difference(new Set(INVERTED_METRICS)))];

const POSITIVE_EMOTIONS = [...new Set(new Set(MENTAL_HEALTH_METRICS).difference(new Set(INVERTED_METRICS)))];

export {MENTAL_HEALTH_METRICS, PRODUCTIVITY_METRICS, PERSONAL_GROWTH_METRICS, ALL_METRICS, POSITIVE_METRICS, INVERTED_METRICS, POSITIVE_EMOTIONS};
