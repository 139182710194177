import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UilArrowLeft } from "@iconscout/react-unicons";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../Styles/DataRequestForm.css';
import { auth, db } from '../Firebase/firebaseConfig';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

const DataRequestForm = () => {
    const [email, setEmail] = useState('');
    const [reason, setReason] = useState('');
    const [errorEmail, setEmailError] = useState('');
    const [errorReason, setReasonError] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userId, setUserId] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setUserEmail(user.email);
                setUserId(user.uid);
            } else {
                toast.error("You must be logged in to request your data.");
                navigate('/login');
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = true;

        setEmailError("");
        setReasonError("");

        // Validate email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError('Invalid email format');
            isValid = false;
        }

        // Check if entered email matches user's email
        if (email !== userEmail) {
            setEmailError('Email does not match your account email');
            isValid = false;
        }

        // Validate reason length
        if (reason.length > 500) {
            setReasonError('Reason must not exceed 500 characters');
            isValid = false;
        }

        if (!isValid) {
            toast.error("Error submitting request. Please try again.");
            return;
        }

        try {
            // Add new data request to Firestore
            await addDoc(collection(db, 'data-requests'), {
                uid: userId,
                email: email,
                reason: reason,
                timestamp: serverTimestamp(),
                resolved: false
            });

            toast.success("Your data request has been submitted. Our support team will process it soon.");
            setEmail('');
            setReason('');
        } catch (error) {
            console.error("Error submitting data request:", error);
            toast.error("Error submitting request. Please try again.");
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div className="data-request-background">
            <div className="data-request-container">
                <header className="data-request-header">
                    <button onClick={handleBack} className="back-button">
                        <UilArrowLeft size="24" color="#ffffff" />
                    </button>
                    <h1 className="data-request-title">Data Subject Access Request Form</h1>
                    <div className="back-icon"></div>
                </header>

                <p>Please fill in the information below. The website administrator or data protection officer will be notified of your request within 24 hours, and will need an appropriate amount of time to respond.</p>

                <form onSubmit={handleSubmit} className="data-request-form">
                    <div>
                        <div className="data-request-section">
                            <label className="data-request-label">Email Address</label>
                            <input
                                type="email"
                                className="data-request-input"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>

                        <div className="data-request-section">
                            <label className="data-request-label"></label>
                            {errorEmail && <p className="data-request-error">{errorEmail}</p>}
                        </div>

                        <div className="data-request-section">
                            <label className="data-request-label">Reason for Request (Optional)</label>
                            <textarea
                                className="data-request-input"
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                maxLength={500}
                                rows={4}
                            />
                        </div>
                        <p className="data-request-char-count">{reason.length}/500 characters</p>

                        <div className="data-request-section">
                            <label className="data-request-label"></label>
                            {errorReason && <p className="data-request-error">{errorReason}</p>}
                        </div>
                    </div>

                    <button type="submit" className="data-request-button">Request Data</button>
                </form>
            </div>
        </div>
    );
};

export default DataRequestForm;
