import React, { useState } from 'react';
import axios from 'axios';
import '../Styles/App.css';

function IOISignup() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/api/email/signup',
        { email },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true  // Enable sending cookies over cross-origin requests
        }
      );
      console.log('Response:', response.data);
      setEmail('');
      setMessage('Thank you for signing up!');
    } catch (error) {
      console.log(error)
      setEmail('');
      setMessage('Error signing up. Please try again.');
    }
  };

  return (
    <div>
      <header className="App-header">
      <h3>DeepKeys</h3>
      <h6>AI-Powered Mental Health Monitoring</h6>
      <h6>Join Our Waitlist to get Early Access and Updates!</h6>

        <form onSubmit={handleSubmit} className="signup-form">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
            className="email-input"
          />
          <button type="submit" className="signup-button">Sign Up</button>
          {message && <p className="signup-message">{message}</p>}
        </form>
      </header>
    </div>
  );
}

export default IOISignup;
