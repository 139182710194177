import React, { useState } from 'react';
import { auth } from '../Firebase/firebaseConfig';
import { sendPasswordResetEmail } from "firebase/auth";
import { handleFirebaseError } from '../Firebase/errorCodes';
import '../Styles/AuthForm.css';
import logo from '../Resources/icon.png';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setMessage('');

        try {
            await sendPasswordResetEmail(auth, email);
            setMessage('Password reset email sent. Please check your inbox.');
            toast.success('Password reset email sent');
        } catch (error) {
            console.log(error);
            setError(handleFirebaseError(error));
        }
    };

    const handleBack = () => {
        navigate('/login');
    };

    return (
        <div className="auth-background">
            <div className="auth-container">
                <img src={logo} alt="Brand Logo" className="brand-logo" />
                <h2 className="auth-title">Reset Password</h2>
                <form onSubmit={handleSubmit} className="auth-form">
                    <input
                        type="email"
                        className="auth-input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        required
                    />
                    <button type="submit" className="auth-button">Send Reset Email</button>
                </form>
                {error && <p className="auth-error">{error}</p>}
                {message && <p className="auth-message">{message}</p>}
                <button onClick={handleBack} className="auth-toggle">
                    Back to Login
                </button>
            </div>
        </div>
    );
}

export default ForgotPassword;
