import { db, auth } from './firebaseConfig';
import { doc, getDoc, setDoc, addDoc, collection, Timestamp } from 'firebase/firestore';

// Function to upload data to Firestore using the current authenticated user
export const uploadMetricData = async (data) => {
    const user = auth.currentUser;

    if (user) {
        console.log("Attempting to upload metric data for user:", user.uid);
        const userDocRef = doc(db, 'users', user.uid); // Ensure this is a valid DocumentReference

        try {
            // Attempt to get the user document
            const docSnap = await getDoc(userDocRef);

            // Check if the user document exists
            if (!docSnap.exists()) {
                console.log("User document does not exist, creating one...");
                // Create the user document if it does not exist
                await setDoc(userDocRef, { createdAt: Timestamp.fromDate(new Date()) });
                console.log("User document created successfully.");
            }

            // Reference to the 'entries' sub-collection
            const entriesCol = collection(userDocRef, 'entries'); // Correct usage of DocumentReference

            // Assume the time provided is already in UTC
            const utcDate = new Date(data.time);

            // Add a new entry to the 'entries' sub-collection
            await addDoc(entriesCol, {
                time: Timestamp.fromDate(utcDate), // Directly use the UTC time
                goals: data.goals.map(goal => ({
                    metric: goal.metric,
                    prediction: goal.prediction
                }))
            });

            console.log("Entry added successfully!");
        } catch (error) {
          console.error("Error uploading metric data:", error);
        }
    } else {
        console.log("No user is currently logged in.");
    }
};
