import React, {useState} from 'react';
import Plot from 'react-plotly.js';
import {metricColorMapping} from '../Utilities/colorMapping'

const CategoryTimeSeriesChart = ({ userData, categories, plotlyConfig, numDays, dimensions, titleBody='Performance Over Time', fill='', showMarkers=false, colorOverride='' }) => {
    const colors = metricColorMapping();
    const [sliderValue, setSliderValue] = useState(5); // Default to the 1-hr interval

    // Interval options for dropdown
    const intervals = [
        { label: 'Point', value: 'point' },
        { label: '1 Minute', value: '1-min' },
        { label: '5 Minutes', value: '5-min' },
        { label: '15 Minutes', value: '15-min' },
        { label: '30 Minutes', value: '30-min' },
        { label: '1 Hour', value: '1-hr' },
        { label: '4 Hours', value: '4-hr' },
        { label: '8 Hours', value: '8-hr' },
        { label: '12 Hours', value: '12-hr' },
        { label: '1 Day', value: '1-day' },
        { label: '1 Week', value: '1-week' }
    ];

    // When slider value changes, update the chart data accordingly
    const handleSliderChange = (event) => {
        const newIndex = parseInt(event.target.value);
        setSliderValue(newIndex);
    };

    // Prepare data based on selected interval
    const aggregationInterval = intervals[sliderValue].value;

    const prepareDataForCategoryPlottingPoint = () => {
        // Initialize plot data for each category
        let plotData = {};
        Object.keys(categories).forEach((category, i) => {
            let fillValue = fill;
            if (fillValue === "tonexty" && i > 0) {
              fillValue = "tozeroy";
            }
            plotData[category] = {
              x: [],
              y: [],
              type: 'scatter',
              mode: showMarkers? 'lines+markers': 'lines',
              name: category,
              marker: {
                color: colorOverride === '' ? colors[category.toLowerCase()] : colorOverride
              },
              hovertemplate: '%{x} %{y:.2f}',
              line: {shape: 'spline', smoothing: 0.5},
              fill: fillValue
            };
        });

        let lastLabel = ""; // To track the last date-hour we labeled
        let customLabels = []; // To store labels and their corresponding positions
        let lastIndex = 0;

        userData.forEach((point, index) => {

            const dateTime = new Date(point.time);
            const dateHour = `${dateTime.toLocaleDateString('en-US').split('T')[0]}`;
            // Determine if this timestamp should get a label
            if (dateHour !== lastLabel) {
              if (lastIndex === 0 || (index-lastIndex)/userData.length > 0.05 ) {
                const formattedDate = dateTime.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                });
                customLabels.push({x: point.time, label: formattedDate});
                lastIndex = index;
              } else {
                customLabels.push({x: point.time, label: ""});
              }
              lastLabel = dateHour;
            }

            Object.keys(categories).forEach(category => {
                let sum = 0;
                let count = 0;

                categories[category].forEach(metric => {
                    if (point.goals[metric] !== undefined) {
                        sum += point.goals[metric];
                        count += 1;
                    }
                });

                if (count > 0) { // Only add if there's data for this category at this time
                    // Always push the timestamp to maintain accurate plotting
                    plotData[category].x.push(point.time);
                    plotData[category].y.push(sum / count * 100); // Average score for the category at this time
                }
            });
        });

        if (Object.keys(categories).length === 1) {
            const category = Object.keys(categories)[0];
            const allValues = [];
            plotData[category].y.forEach(value => allValues.push(value));
            const meanValue = allValues.reduce((a, b) => a + b, 0) / allValues.length;

            // Create a new trace for the mean line
            plotData[category+'-baseline'] = {
                x: plotData[category].x,
                y: Array(plotData[category].x.length).fill(meanValue),
                type: 'scatter',
                mode: 'lines',
                name: 'Baseline',
                line: {
                    dash: 'dash',
                    color: '#aaa'
                }
            };
        }

        return {data: Object.values(plotData), labels: Object.values(customLabels)};
    };

    const prepareDataForCategoryPlottingInterval = (aggregationInterval) => {
        const plotData = {};
        Object.keys(categories).forEach((category, i) => {
            let fillValue = fill;
            if (fillValue === "tonexty" && i > 0) {
              fillValue = "tozeroy";
            }
            plotData[category] = {
              x: [],
              y: [],
              type: 'scatter',
              mode: showMarkers? 'lines+markers': 'lines',
              name: category,
              marker: {
                color: colorOverride === '' ? colors[category.toLowerCase()] : colorOverride
              },
              hovertemplate: '%{x} %{y:.2f}',
              line: {shape: 'spline', smoothing: 0.5},
              fill: fillValue
            };
        });

        let lastLabel = ""; // To track the last date-hour we labeled
        let customLabels = []; // To store labels and their corresponding positions
        const intervalMilliseconds = {
            '1-week': 86400000*7,
            '1-day': 86400000,
            '12-hr': 43200000,
            '8-hr': 28800000,
            '4-hr': 14400000,
            '1-hr': 3600000,
            '30-min': 1800000,
            '15-min': 900000,
            '5-min': 300000,
            '1-min': 60000
        }[aggregationInterval];

        const groupedData = {};
        userData.forEach(point => {
            const roundedTime = Math.floor(new Date(point.time).getTime() / intervalMilliseconds) * intervalMilliseconds;
            Object.keys(categories).forEach(category => {
                if (!groupedData[category]) groupedData[category] = {};
                if (!groupedData[category][roundedTime]) groupedData[category][roundedTime] = [];
                let sum = 0;
                let count = 0;
                categories[category].forEach(metric => {
                    if (point.goals[metric] !== undefined) {
                        sum += point.goals[metric];
                        count++;
                    }
                });
                if (count > 0) {
                    groupedData[category][roundedTime].push(sum / count);
                }
            });
        });

        Object.keys(groupedData).forEach(category => {
            let lastIndex = 0;
            Object.entries(groupedData[category]).forEach(([time, values], index) => {
                if (values.length > 0) {
                    const avg = values.reduce((a, b) => a + b, 0) / values.length * 100;
                    const pointTime = new Date(parseInt(time));
                    plotData[category].x.push(pointTime);
                    plotData[category].y.push(avg);
                    const dateTime = new Date(pointTime);
                    const dateHour = `${dateTime.toLocaleDateString('en-US').split('T')[0]}`;
                    // Determine if this timestamp should get a label
                    if (dateHour !== lastLabel) {
                      if (lastIndex === 0 || (index - lastIndex) / Object.keys(groupedData[category]).length > 0.05) {
                        const formattedDate = dateTime.toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric'
                        });
                        customLabels.push({x: pointTime, label: formattedDate});
                        lastIndex = index;
                      } else {
                        customLabels.push({x: pointTime, label: ""});
                      }
                      lastLabel = dateHour;
                    }
                }
            });
        });

        if (Object.keys(categories).length === 1) {
            const category = Object.keys(categories)[0];
            const allValues = [];
            plotData[category].y.forEach(value => allValues.push(value));
            const meanValue = allValues.reduce((a, b) => a + b, 0) / allValues.length;

            // Create a new trace for the mean line
            plotData[category+'-baseline'] = {
                x: plotData[category].x,
                y: Array(plotData[category].x.length).fill(meanValue),
                type: 'scatter',
                mode: 'lines',
                name: 'Baseline',
                line: {
                    dash: 'dash',
                    color: '#aaa'
                }
            };
        }

        return {data: Object.values(plotData), labels: Object.values(customLabels)};
    };


    const linePlotDataAndLabels = aggregationInterval === 'point' ? prepareDataForCategoryPlottingPoint() : prepareDataForCategoryPlottingInterval(aggregationInterval);
    const linePlotData = linePlotDataAndLabels.data;
    const linePlotLabels = linePlotDataAndLabels.labels;

    let titleText = titleBody; //+' (Last '+numDays.toString()+" Days)";
    // if (aggregationInterval === "1-day") {
    //   titleText = "Daily " + titleText;
    // } else if (aggregationInterval === "1-week") {
    //     titleText = "Weekly " + titleText;
    // } else if (aggregationInterval !== "point") {
    //   titleText = aggregationInterval + " Avg. " + titleText;
    // }

    const layout = {
        title: {
          text: titleText,
          font: {
            color: "#FFFFFF",
            size: 32
          }
        },
        xaxis: {
          // title: {
          //   text: 'Date',
          //   font: {
          //     size: 16,
          //     color: "#ffffff",
          //   },
          // },
          type: 'category',
          tickvals: linePlotLabels.map(label => label.x),
          ticktext: linePlotLabels.map(label => label.label),
          gridcolor: 'rgba(167,164,164, 0.25)',
          tickangle: 45,
          tickfont: {
            color: '#ffffff'
          },
        },
        legend: {
          font: {
            color: '#ffffff'
          }
        },
        yaxis: {
          // title: {
          //   text: 'Score',
          //   font: {
          //     size: 16,
          //     color: "#ffffff",
          //   },
          // },
          gridcolor: 'rgba(167,164,164, 0.25)',
          zerolinecolor: '#fff',
          zeroline: true,
          linecolor: '#fff',
          range: [0, 100],
          tickfont: {
            color: '#ffffff'
          },
        },
        margin: { l: 80, r: 80, t: 80, b: 80 },
        width: dimensions.width * 0.625,
        height: dimensions.height * 0.5,
        paper_bgcolor: 'transparent',
        plot_bgcolor: 'transparent',
    };

    return (
      <div>
        <Plot
            data={linePlotData}
            layout={layout}
            config={plotlyConfig}
        />
        <div style={{ marginBottom: '20px' }}>
            <input
                type="range"
                min="0"
                max={intervals.length - 1}
                value={sliderValue}
                onChange={handleSliderChange}
                step="1"
                style={{ width: '50%' }}
            />
            <div>
                <label>
                    Interval: {intervals[sliderValue].label}
                </label>
            </div>
        </div>
      </div>
    );
};

export default CategoryTimeSeriesChart;
