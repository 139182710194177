import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UilUser, UilSpinner, UilArrowUp, UilArrowDown, UilTrophy } from "@iconscout/react-unicons";
import { auth, storage } from '../Firebase/firebaseConfig';
import { getFollowersAndFollowing } from '../Firebase/followUtils';
import { getUserBasicInfoFromUid, getQuerySnapFromUsername } from '../Firebase/readUserDetails';
import { ref, getDownloadURL } from 'firebase/storage';
import { fetchEntriesInRange } from '../Firebase/fetchEntriesInRange';
import { SingleMetricDataModel } from '../Utilities/DataModel';
import { MENTAL_HEALTH_METRICS, PRODUCTIVITY_METRICS, PERSONAL_GROWTH_METRICS, ALL_METRICS } from '../Utilities/metricGrouping';
import { motion } from "framer-motion";
import '../Styles/Dashboard.css';
import '../Styles/Leaderboard.css';
import SideBar from '../Components/SideBar';

function Leaderboard() {
    const [followData, setFollowData] = useState({ followers: [], following: [], lastVisible: { followers: null, following: null } });
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: 'overallScore', direction: 'descending' });
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            if (!auth.currentUser) {
                console.log("No user logged in");
                navigate('/login');
                return;
            }

            const currentUserInfo = await getUserBasicInfoFromUid(auth.currentUser.uid);
            setCurrentUser(currentUserInfo);

            const followInfo = await getFollowersAndFollowing(auth.currentUser.uid);
            setFollowData(followInfo);

            const fetchLeaderboardData = async () => {
                setIsLoading(true);
                try {
                    const userDetails = await Promise.all(
                        [...followInfo.following, { id: auth.currentUser.uid }].map(async (user) => {
                            const userInfo = await getUserBasicInfoFromUid(user.id);
                            const profilePicUrl = await fetchProfilePic(user.id);

                            const querySnapshot = await getQuerySnapFromUsername(userInfo.username);
                            if (!querySnapshot) {
                                return null;
                            }
                            const username = userInfo.username;

                            const endDate = new Date();
                            const startDate = new Date(Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate()));

                            const data = await fetchEntriesInRange(startDate, endDate, true, username);

                            const mentalHealthModel = new SingleMetricDataModel(data, MENTAL_HEALTH_METRICS);
                            const productivityModel = new SingleMetricDataModel(data, PRODUCTIVITY_METRICS);
                            const personalGrowthModel = new SingleMetricDataModel(data, PERSONAL_GROWTH_METRICS);
                            const overallModel = new SingleMetricDataModel(data, ALL_METRICS);

                            const mentalHealthScore = mentalHealthModel.calculateTodaysScore();
                            const productivityScore = productivityModel.calculateTodaysScore();
                            const personalGrowthScore = personalGrowthModel.calculateTodaysScore();
                            const overallScore = overallModel.calculateTodaysScore();

                            return {
                                ...user,
                                ...userInfo,
                                profilePic: profilePicUrl,
                                mentalHealthScore: parseFloat(mentalHealthScore),
                                productivityScore: parseFloat(productivityScore),
                                personalGrowthScore: parseFloat(personalGrowthScore),
                                overallScore: parseFloat(overallScore),
                            };
                        })
                    );

                    const filteredUserDetails = userDetails.filter(user => user !== null && user.overallScore !== undefined && user.overallScore !== 0);
                    setLeaderboardData(filteredUserDetails);
                } catch (error) {
                    console.error("Error fetching leaderboard data:", error);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchLeaderboardData();
        };
        fetchData();
    }, [navigate]);

    useEffect(() => {
        const sortedData = [...leaderboardData].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setLeaderboardData(sortedData);
    }, [sortConfig]);

    const fetchProfilePic = async (userId) => {
        const profilePicRef = ref(storage, `profilePictures/${userId}`);
        try {
            const url = await getDownloadURL(profilePicRef);
            return url;
        } catch (error) {
            console.log('No profile picture found, using default');
            return null;
        }
    };

    const handleUserClick = (username) => {
        navigate(`/user-profile/${username}`);
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        return (
            <div className="sort-icon-container">
                <UilArrowUp 
                    size='20'
                    color={sortConfig.key === key && sortConfig.direction === 'ascending' ? 'white' : 'gray'}
                />
                <UilArrowDown 
                    size='20'
                    color={sortConfig.key === key && sortConfig.direction === 'descending' ? 'white' : 'gray'}
                />
            </div>
        );
    };

    return (
        <div className="dashboard-container">
            <div className="dashboard-content">
                <div className="dashboard-split">
                    <SideBar activeItem="Leaderboard" />
                        <motion.div
                            className="leaderboard-container"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                        >
                            <h2 className="leaderboard-title">
                            <UilTrophy size="28" className="leaderboard-icon" /> Leaderboard
                            </h2>
                            {isLoading ? (
                                <div className="loading-spinner">
                                    <UilSpinner size="40" color="white" className="spinner-icon" />
                                </div>
                            ) : leaderboardData.length > 0 ? (
                                <div className="leaderboard-scroll-container">
                                    <ul className="leaderboard-list">
                                        <li className="leaderboard-header">
                                            <span className="rank">Rank</span>
                                            <span className="user">User</span>
                                            <span className="mental-health" onClick={() => handleSort('mentalHealthScore')}>
                                                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    Mental Health {getSortIcon('mentalHealthScore')}
                                                </span>
                                            </span>
                                            <span className="productivity" onClick={() => handleSort('productivityScore')}>
                                                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    Productivity {getSortIcon('productivityScore')}
                                                </span>
                                            </span>
                                            <span className="personal-growth" onClick={() => handleSort('personalGrowthScore')}>
                                                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    Personal Growth {getSortIcon('personalGrowthScore')}
                                                </span>
                                            </span>
                                            <span className="overall" onClick={() => handleSort('overallScore')}>
                                                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    Overall {getSortIcon('overallScore')}
                                                </span>
                                            </span>
                                        </li>
                                        {leaderboardData.map((user, index) => (
                                            <li 
                                                key={user.id} 
                                                className="leaderboard-item" 
                                                onClick={() => handleUserClick(user.username)}
                                                style={user.id === auth.currentUser.uid ? {backgroundColor: 'var(--dashboardMidDark)'} : {}}
                                            >
                                                <span className="rank">
                                                    {sortConfig.direction === 'descending' 
                                                        ? index + 1 
                                                        : leaderboardData.length - index}
                                                </span>
                                                <div className="user-info">
                                                    {user.profilePic ? (
                                                        <img src={user.profilePic} alt={user.username} className="user-pic" />
                                                    ) : (
                                                        <UilUser className="user-pic" />
                                                    )}
                                                    <div className="user-details">
                                                        <span className="username">{user.username}</span>
                                                        <span className="name">{`${user.firstName} ${user.lastName}`}</span>
                                                    </div>
                                                </div>
                                                <span className="mental-health-score">{user.mentalHealthScore.toFixed(2)}</span>
                                                <span className="productivity-score">{user.productivityScore.toFixed(2)}</span>
                                                <span className="personal-growth-score">{user.personalGrowthScore.toFixed(2)}</span>
                                                <span className="overall-score">{user.overallScore.toFixed(2)}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : (
                                <p className="leaderboard-empty-message">
                                    There's no one in your leaderboard right now. Please follow more people to see their scores here.
                                </p>
                            )}
                        </motion.div>
                </div>
            </div>
        </div>
    )
}

export default Leaderboard;
