import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {canViewUserEntries} from '../Firebase/readUserDetails';
import UserProfile from './UserProfile';
import PrivateUser from './PrivateUser';
import Loading from '../Pages/Loading';

function UserProfileViewer() {
  const { username } = useParams();
  const [canView, setCanView] = useState(null);

  useEffect(() => {
    async function checkAccess() {
      const hasAccess = await canViewUserEntries(username);
      console.log("hasAccess", hasAccess);
      setCanView(hasAccess);
    }

    checkAccess();
  }, [username]);

  if (canView === null) {
    return <Loading/>;
  }

  return canView ? <UserProfile/> : <PrivateUser/>;
}

export default UserProfileViewer;
