import React, { useState, useEffect, useRef } from "react";
import { UilAnalysis, UilTimes } from "@iconscout/react-unicons";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import CategoryTimeSeriesChart from './CategoryTimeSeriesChart';
import SummaryComponent from './SummaryComponent';
// import CircularProgressBarDelta from './CircularProgressBarDelta';
import 'react-circular-progressbar/dist/styles.css';

import "../Styles/Card.css";

import { toast } from 'react-toastify';

import { motion, LayoutGroup } from "framer-motion";

const Card = ({title, color, pointsToday, dayScore, dayStandardDeviation, dayMax, dayMin, date, baselineScore, userData, categories, plotlyConfig, numDays, dimensions}) => {

  const [expanded, setExpanded] = useState(false);

  const expandedCardRef = useRef(null);

  useEffect(() => {
      const handleClickOutside = (event) => {
          if (expandedCardRef.current && !expandedCardRef.current.contains(event.target)) {
              setExpanded(false);
          }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, []);

  return (
    <>
    {expanded && <div className="overlay" onClick={() => setExpanded(false)}></div>}
    <LayoutGroup className="card-container">
      {expanded ?
        <div ref={expandedCardRef}>
          <ExpandedCard
            title={title}
            color={color}

            dayScore={dayScore}
            dayStandardDeviation={dayStandardDeviation}
            date={date}
            baselineScore={baselineScore}

            setExpanded={()=>{setExpanded(false);}}

            userData={userData}
            categories={categories}
            plotlyConfig={plotlyConfig}
            numDays={numDays}
            dimensions={dimensions}
          />
        </div> :
        <CompactCard
          title={title}
          color={color}

          pointsToday={pointsToday}
          dayScore={dayScore}
          dayMax={dayMax}
          dayMin={dayMin}
          dayStandardDeviation={dayStandardDeviation}
          date={date}
          baselineScore={baselineScore}

          setExpanded={()=>{setExpanded(true);}}
          />
        }
    </LayoutGroup>
    </>
  );
};

const CompactCard = ({title, color, pointsToday, dayScore, dayMax, dayMin, dayStandardDeviation, date, baselineScore, setExpanded, userData, categories, plotlyConfig, numDays, dimensions}) => {
  const baselineDiff = pointsToday === 0 ? 0 : (dayScore-baselineScore).toFixed(0).replace('-0', '0');
  const stdDev = Number(dayStandardDeviation).toFixed(0).replace('-0', '0');

  const circularProgressBarText = pointsToday === 0 && dayScore === 0? "No data today!": dayScore.toString()+"%";
  const circularProgressValue = pointsToday === 0 && dayScore === 0? 0: dayScore;
  const lowerBound = (Number(dayScore)-Number(dayStandardDeviation)).toFixed(0).replace('-0', '0');
  const upperBound = (Number(dayScore)+Number(dayStandardDeviation)).toFixed(0).replace('-0', '0');

  return (
    <div layoutId="compact-card" className="compact-card" onClick={setExpanded} style={{background: color.background, boxShadow: color.boxShadow}}>
      <div className="radial-bar">
        <CircularProgressbar
          value={circularProgressValue}
          text={circularProgressBarText}
          styles={pointsToday !== 0? {}: buildStyles({
            textSize: '0.875rem',
          })}
        />
        <span>{date}</span>
      </div>
      <div className="compact-details">
        <span>{title}</span>
        <span>{baselineDiff}% {baselineDiff>=0? 'above': 'below'} baseline</span>
        <span>Show more</span>
      </div>
    </div>
  )
}

const ExpandedCard = ({title, color, setExpanded, userData, categories, plotlyConfig, numDays, dimensions}) => {
  return (
    <motion.div layoutId="expanded-card" className="expanded-card">
      <div className="expanded-card-close"><UilTimes onClick={setExpanded}/></div>
      <div className="expanded-card-chart-container">

        {categories.map(category => {
          return (
            <div>
              <CategoryTimeSeriesChart
                  className="expanded-card-chart-time-series"
                  userData={userData}
                  categories={category.mapping}
                  plotlyConfig={plotlyConfig}
                  numDays={numDays}
                  dimensions={dimensions}
                  titleBody={category.title}
                  fill={category.fill}
              />
              <div className='expanded-card-summary-container'>
                <SummaryComponent
                apiUrl={process.env.REACT_APP_DKAI_SUMMARIZE_OVERALL}
                userData={userData}
                categories={category.mapping}
                title={category.title + " Summary"}/>
              </div>
            </div>
          )
        })
        }
      </div>
    </motion.div>
  )
}

export default Card;
